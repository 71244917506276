@use "theme/variables" as *;

.c-market-explore {

    .panel-heading {
        padding: $spacer-1;
    }

    .panel-body {
        background: $default-6;
        margin: 0;
        padding: $spacer-1;

        .c-panel-body-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: $spacer-1;
        }
    }

    .c-data-card {
        border: 1px solid $default-4;
        margin-bottom: $spacer-1;
        max-width: 500px;
    }

    .c-explore-search-fields {


        .explore-search-filters {

            .product-filter-group {
                background: $default-6;
                border: 1px solid $default-4;
                flex-wrap: wrap;
                max-width: $breakpoint-40;
                padding: $spacer-2;

                .c-field-number {
                    max-width: 90px;
                }

                label {
                    color: $default-1;
                }
            }
        }
    }
}

.c-data-card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.c-data-card-container .c-data-card {
    flex: 1 1 calc(33.333% - 16px);
    max-width: calc(33.333% - 16px);
}
