@use "theme/variables" as *;
@use "sass:list";

.c-icon {
    fill: currentcolor;

    &.warning {
        color: hsl(var(--warning-h) var(--s-3) var(--l-2));
    }

    &.error {
        color: var(--danger-4);
    }

    &.action {
        cursor: pointer;
    }

    &.disabled {
        cursor: not-allowed;
        opacity: 0.7;
    }

    &.type-default {
        color: hsl(var(--default-h) var(--s-1) var(--l-3));

        *.active,
        &:hover {
            color: hsl(var(--info-h) var(--s-1) var(--l-1));
        }
    }

    &.type-brand {
        color: hsl(var(--brand-h) var(--s-3) var(--l-3));
    }

    &.type-success {
        color: hsl(var(--success-h) var(--s-3) var(--l-1));
    }

    &.type-info {
        color: hsl(var(--info-h) var(--s-3) var(--l-2));
    }

    &.type-warning {
        color: hsl(var(--warning-h) var(--s-3) var(--l-3));
    }

    // Use type-error; type-danger is deprecated.
    &.type-error { color: hsl(var(--danger-h) var(--s-3) var(--l-3)); }
    &.type-danger { color: hsl(var(--danger-h) var(--s-3) var(--l-3)); }

    // An icon with a tip must have it initialized oncreate; this class
    // just prevents conditional empty tips to be rendered when hovering.

    &.tip-empty {
        pointer-events: none;
    }
}


.icon-xs {
    height: $icon-xs;
    width: $icon-xs;
}

.icon-s {
    height: $icon-s;
    width: $icon-s;
}

.icon-d {
    height: $icon-d;
    width: $icon-d;
}

.icon-l {
    height: $icon-l;
    width: $icon-l;
}

.icon-xl {
    height: $icon-xl;
    width: $icon-xl;
}

.icon-xxl {
    height: $icon-xxl;
    width: $icon-xxl;
}

