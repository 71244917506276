@use "theme/variables" as *;

.c-resolve-source-line {
    width: 100%;

    .suggestions {
        background: $default-6;
        width: 100%;

        .persuade {
            align-items: center;
            background: $default-4;
            color: $default-1;
            display: flex;
            font-style: italic;
            gap: $spacer-1;
            padding: $spacer-1;
            width: 100%;

            span {
                // flex: 1;
                width: 100%;
            }

            .c-icon {
                flex: 1;
                min-width: $icon-l;
            }
        }

        .table {

            tr {
                cursor: pointer;
            }
        }
    }
}
