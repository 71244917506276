@use "theme/variables" as *;

.c-widget-individual-sales.dashboard-widget {
    max-height: unset;

    .widget-body {
        align-items: center;
        display: flex;
        justify-content: center;

        .figures-wrapper {
            display: flex;
            flex-direction: column;

            .c-figures-widget {
                padding: $spacer-1 0;
            }
        }

        .portal-charts {
            display: flex;


            .chart-wrapper {

                canvas {
                    max-height: 250px;
                }
            }
        }
    }
}
