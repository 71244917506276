@use "theme/variables" as *;

.product-category-table {
    margin-top: 24px;
}

.float-right {
    float: right;

    &::after {
        clear: both;
    }
}

.clickable {
    cursor: pointer;
}

.unassigned-product-categories {
    margin: $spacer-2 0;

    .title {
        font-weight: 600;
    }
}
