@use "theme/variables" as *;

.c-lot {
    display: flex;
    flex-direction: column;
    font-size: $font-s;
    justify-content: center;

    .c-icon {
        margin-right: $spacer-025;
    }

    .lot-warehouse {
        color: $default-2;
        font-weight: 500;
    }

    .lot-reference {
        display: flex;
    }
}
