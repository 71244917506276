@use "common/lib/mixins" as *;
@use "theme/variables" as *;

.c-support {
    align-items: center;
    bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 35px;
    position: fixed;
    width: 150px;
    z-index: 1000000;

    .support-icon {

        &:hover {
            color: $default-1;
        }
    }

    &.collapsed-support {

        .manager-contact {
            background-image: none;
            max-height: 0;
            min-height: 0;
            min-width: 0;
            opacity: 0;
            overflow: hidden;


            // Disable clicks, so the support collapsed icon can receive an event.
            pointer-events: none;
        }

        .manager-contact-options,
        .manager-name,
        .manager-title {
            display: none;
            user-select: none;
        }
    }

    .manager-contact-options {
        display: flex;
        gap: $spacer-1;
        margin-bottom: $spacer-1 * 1.5;
        z-index: 20;

        .c-button {
            background: $info-1;
            box-shadow: $shadow-down-l;
        }
    }

    .manager-title {
        align-items: center;
        color: $info-2;
        display: flex;
        font-size: $font-l;
        font-weight: 600;
        gap: $spacer-1;
        justify-content: center;
        margin-bottom: $spacer-1 * 1.5;
        max-height: 100%;
        user-select: none;
        white-space: nowrap;
        width: 100%;

        &:hover {
            color: $info-1;
        }
    }

    .manager-contact {
        background-size: cover;
        border: 2px solid $info-1;
        border-radius: 50%;
        box-shadow: $shadow-down-l;
        min-height: 125px;
        min-width: 125px;
        transition: all 0.3s ease;
    }

    .manager-name {
        color: $info-1;
        margin-top: $spacer-05;
        padding: $spacer-1;
    }

    .c-notifications {
        position: fixed;
    }

    &:not(.collapsed) .c-notifications {
        bottom: 110px;
        left: 190px;

        .notification {

            &:last-child::before {
                clip-path: polygon(100% 100%, 100% 0, 0 50%);
                content: "";
                display: block;
                height: 20px;
                left: -20px;
                position: absolute;
                width: 20px;
            }
        }
    }

    &.collapsed-support:not(.collapsed) .c-notifications {
        bottom: 35px;
        left: 150px;
    }

    &.collapsed {
        bottom: 0;
        left: -25px;
        margin-bottom: -20px;
        margin-left: 0;
        position: fixed;
        width: unset;
        z-index: 1000000;

        .manager-name,
        .manager-title {
            display: none;
        }

        .manager-contact {
            min-height: 100px;
            min-width: 100px;
            z-index: 10;
        }

        .manager-contact-options {
            @include on-circle(9, 100px, 20px, -142);

            position: absolute;

            > .c-button {
                height: $spacer-3;
                width: $spacer-3;

                svg {
                    @include square($spacer-2);
                }
            }
        }

        .c-notifications {
            bottom: 105px;
            left: 55px;

            @media (min-width: $breakpoint-10) {
                // The support manager icon/photo is only on tablet/desktop.
                // For mobile, the default arrow is used.

                .c-notification {

                    &:last-child::before {
                        bottom: -50px;

                        // Points text balloon arrow of notifications down when collapsed
                        clip-path: polygon(50% 0, 100% 0, 0 100%);
                        content: "";
                        height: 50px;
                        left: 0;
                        position: absolute;
                        width: 50px;
                    }
                }
            }
        }

        &.collapsed-support .c-notifications {
            bottom: 160px;
        }
    }

    &.toggleable {
        cursor: pointer;
    }

    @media (max-width: $breakpoint-10) {

        &.collapsed {

            &:not(.collapsed-support) {

                .manager-contact {
                    display: none;
                }
            }


            .manager-contact-options {
                display: none;
            }

            .c-notifications {
                bottom: 0;
                left: 0;
                padding: $spacer-1;
                width: 100vw;

                .notification {
                    width: 100%;

                    &:last-child::before {
                        display: none;
                    }
                }
            }
        }
    }
}
