@use "theme/variables" as *;

.c-bar-main {

    .bar-search {
        max-width: unset !important;

        .vendor-select {
            margin-bottom: 0;

            select {

                &,
                & option {
                    background: $info-2;
                    color: $info-6;
                }
            }
        }
    }

    .c-field-switch {
        flex-direction: row-reverse;
        gap: $spacer-1;
        margin-bottom: 0;
        margin-left: $spacer-1;

        label {
            color: $info-6;
        }
    }
}
