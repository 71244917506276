@use "theme/variables" as *;

.c-my-discover-dashboard {

    .widget {
        align-items: center;
        border-top: 0;
        display: flex;
        flex-direction: column;
        min-height: 170px;
        padding: 3px;

        a {
            align-items: center;
            color: $grey-1;
            display: flex;
            flex-direction: column;
        }

        .figure {
            font-size: 16px;
        }

        .title {
            font-size: large;
            font-weight: bold;
            padding: 5px;
            text-align: center;
            text-transform: uppercase;
        }

        .blue {
            color: #0196bb;
        }

        .purchase {
            color: #007aaf;
        }

        .sales {
            color: #f1c40f;
        }

        .sub-title {
            color: $grey-1;
            text-align: center;
        }

        .icon {
            font-size: larger;
        }
    }
}

.leaflet-marker-icon {
    color: #d25a5a;
}

.goals-progress,
.index-gauges {

    .column-header {
        font-size: larger;
        font-weight: bold;
    }

    .progress-group {

        .progress-bar-title {
            font-size: medium;
        }

        .progress-bar-in-value {
            font-size: small;
        }
    }
}
