@use "theme/variables" as *;

.c-issues {

    .dialog {
        width: 600px;
    }

    .btn-close {

        &:hover {
            color: $info-3;
        }
    }

    .btn-expand {
        display: none;
    }

    img {
        max-width: 100%;
    }

    .header {
        align-items: center;
        background: $info-2;
        color: $info-5;
        cursor: pointer;
        display: flex;
        font-weight: 600;
        height: $spacer-5;
        padding: $spacer-1;

        .expanded {
            align-items: center;
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
    }

    .body {

        .c-field-textarea {

            textarea {
                min-height: 100px;
            }
        }

        &.thanks {

            img {
                margin-bottom: $spacer-1;
            }

            p {
                line-height: 1.5rem;
            }
        }
    }
}

