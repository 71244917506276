@use "theme/variables" as *;

.c-multi-input {
    align-items: center;
    border: 1px solid $input-border-color;
    display: flex;
    flex-flow: wrap;
    gap: $spacer-05;
    justify-content: flex-start;
    margin-bottom: $input-margin;
    max-width: 600px;
    min-height: $input-height;
    padding: 0 $spacer-05;

    &.focus {
        outline: $focus-outline;
    }

    .label {
        align-items: center;
        background: hsl(var(--info-h) var(--s-3) var(--l-1));
        cursor: pointer;
        display: flex;
        font-size: 0.9em;
        height: $input-height;
        padding: $spacer-05;
        text-align: center;

        .remove {
            margin-left: $spacer-05;
        }

        &.label-primary {
            background: hsl(var(--info-h) var(--s-3) var(--l-2));
        }

        &:focus {
            outline: none;
        }
    }

    .placeholder {
        align-items: center;
        color: $input-placeholder-color;
        display: flex;
        height: $input-height;
        padding: $spacer-05;
    }

    .input-container {
        margin: 0;
        position: relative;

        .input {
            border: none;
            height: $input-height;
            line-height: 1em;
            padding: 0.525rem;

            &:focus {
                outline: none;
            }
        }

        .input-autocomplete {
            // See html_components.scss for similar components;
            // c-search-bar & c-search-input
            background: $white;
            border: 1px solid $input-border-color;
            box-shadow: $shadow-down-l;
            cursor: default;
            left: 0;
            margin: 0;
            max-height: 320px;
            max-width: 600px;
            min-width: 400px;
            overflow: auto;
            padding: 0;
            position: absolute;
            resize: horizontal;
            z-index: 1000;

            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;

                li {
                    cursor: pointer;
                    overflow: hidden;
                    padding: 10px 5px;
                    white-space: nowrap;

                    &.highlighted {
                        background: hsl(var(--info-h) var(--s-3) var(--l-1));
                        color: hsl(var(--info-h) var(--s-3) var(--l-3));
                    }
                }
            }
        }
    }
}
