@use "theme/variables" as *;

.c-filter-radiobutton {

    .btn-group {
        margin: 0;
        padding: 0;

        .btn {
            align-items: center;
            display: flex;
            height: $input-height;
            justify-content: center;
            position: relative;

            input[type="radio"] {
                height: $input-height;
                justify-content: center;
                left: 0;
                line-height: $input-height;
                width: 100%;
            }
        }
    }
}
