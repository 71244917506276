@use "theme/variables" as *;

.element {
    display: flex;
    padding: $spacer-05 0;

    .help {
        font-size: $font-s;
        font-style: italic;
    }

    .label {
        color: var(--info-1);
        margin-bottom: $spacer-05;
    }
}
