@use "theme/variables" as *;

.c-filter-select-range {
    overflow: hidden;

    // overflow-y: hidden;

    &:not(.active) {

        .noUi-connect {
            background: $grey-5;
        }

        .noUi-target {

            .noUi-base {

                .noUi-connects {

                    .noUi-connect {
                        background: $info-4;
                    }
                }
            }
        }
    }

    &.active {

        .noUi-target {

            &.noUi-horizontal {

                .noUi-origin {

                    .noUi-tooltip {
                        color: $brand-3;
                    }
                }
            }
        }
    }

    .items {
        height: 110px;
        overflow-y: hidden;
        width: 100%;
    }

    .c-slider {
        // Get rid of those pesky scrollbars
        padding-left: $spacer-2 + 2px;
        padding-right: $spacer-2 + 2px;
        padding-top: $spacer-6;
    }
}
