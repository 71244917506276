@use "theme/variables" as *;

.c-statistics-body {
    padding: $spacer-4;

    .c-buttons {
        display: flex;
        gap: $spacer-1;
        margin-bottom: $spacer-4;
    }


    .c-filters {
        display: flex;
        gap: $spacer-2;

        .filter-option {
            flex-direction: column;
        }
    }


    .c-date-range-picker {

        .ranges {
            display: flex;
            flex-flow: row wrap;
            gap: $spacer-1;
        }
    }

    .c-diff-select {
        display: flex;
        flex-flow: row wrap;
        gap: $spacer-2;
    }
}
