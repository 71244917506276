@use "theme/variables" as *;

.c-columns-picker {
    width: 100%;

    .cells {

        .cell {
            background: $success-5;
            color: $success-2;

            .column-header {

                .c-icon {
                    color: $success-3;
                }
            }

            &.invalid {
                background: $danger-5;
                color: $danger-2;

                .column-header {

                    .c-icon {
                        color: $danger-2;
                    }
                }
            }

            &.disabled {
                background: $grey-5;

                .column-header {
                    color: $grey-3;

                    .c-icon {
                        color: $grey-3;
                    }
                }
            }
        }
    }

    &.type-list {

        .field-placeholder {
            display: none;
        }

        .cells {
            display: flex;
            flex-direction: column;
            gap: $spacer-05;

            .cell {
                display: flex;
                padding: 0 $spacer-1;

                .column-header {
                    align-items: center;
                    display: flex;
                    flex: 1;
                    font-weight: 500;
                    gap: $spacer-1;
                    height: $spacer-5;
                }

                .field {
                    margin-bottom: 0;
                }
            }
        }
    }

    &.type-table-header {

        .field-placeholder {
            align-items: center;
            background: $grey-5;
            color: $grey-3;
            display: flex;
            flex: 1;
            height: $input-height;
            padding: $spacer-1;
            width: 100%;
        }

        .item {
            background: none;

            &:hover {
                background: none !important;
                cursor: default;
            }
        }

        .cell {
            flex-direction: column;
            justify-content: center;
            white-space: auto;
            width: 100%;

            .column-header {
                align-items: center;
                display: flex;
                flex: 1;
                flex-direction: column;
                font-weight: 600;
                padding: $spacer-05 0;
                width: 100%;
            }

            .field {
                margin: 0;
                max-width: 100%;
                width: 100%;

                select {
                    border: 0;
                }
            }
        }
    }
}
