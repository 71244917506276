@use "theme/variables" as *;

.c-sourceline-info {
    background: $default-6;
    display: flex;
    justify-content: flex-end;

    .image-suggestions {
        align-items: center;
        background: $default-6;
        display: flex;
        flex: 1;
        justify-content: center;
        width: 100%;
    }

    div.source {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        width: 450px;

        .audit-logs {
            display: flex;
            min-height: $spacer-6;
            width: 100%;

            .header {
                align-items: center;
                display: flex;
                font-weight: 600;
                gap: $spacer-1;
                padding: $spacer-1;
            }

            .details {
                align-items: center;
                display: flex;
                flex-direction: column;
                font-size: $font-s;
                gap: $spacer-1;
                justify-content: center;
                padding: $spacer-1;
                width: 100%;

                a {
                    cursor: pointer;
                }

                span {
                    text-align: center;
                }

                .audit-line {
                    width: 100%;
                }
            }
        }

        .cells {

            .cell {
                min-height: unset;
                padding: $spacer-05 $spacer-1;

                .key {
                    font-weight: 500;
                    width: 100%;
                }
            }
        }
    }

    &.type-warning {
        background: $warning-4;

        .audit-logs {
            color: $warning-2;
        }

        .cell {

            &:nth-child(odd) {
                background: $warning-5;
            }

            &:nth-child(even) {
                background: $warning-6;
            }

            .key {
                color: $warning-1;
            }

            .value {
                color: $default-2;
            }
        }
    }

    &.type-success {
        background: $success-4;

        .audit-logs {

            &,
            & a {
                color: $success-2;
            }
        }


        .cell {

            &:nth-child(odd) {
                background: $success-5;
            }

            &:nth-child(even) {
                background: $success-6;
            }
        }
    }
}
