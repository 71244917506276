@use "theme/variables" as *;

.c-radio-group {
    flex-direction: column;

    .item {
        display: flex;
        padding: $spacer-1 * 0.25 0;

        label {
            align-items: center;
            display: flex;
        }
    }

    .help {
        margin-top: $spacer-05;
    }
}
