@use "theme/variables" as *;

.c-send-purchase-order-to-loendersloot {

    form {

        .fieldset {

            .btn-toolbar {
                align-items: start;
                display: flex;
                flex-direction: row;
                row-gap: 0;

                button {
                    border: 0;
                    margin: 0;
                }
            }
        }
    }
}
