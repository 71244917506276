@use "theme/variables" as *;

.c-file-drop-area {
    background-color: $white;
    background-image: url("/img/upload.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100px;
    border-color: $default-4;
    border-style: dashed;
    border-width: 2px;
    max-width: $panel-width-l;
    padding: $spacer-2 $spacer-1;
    position: relative;
    width: 100%;


    .background {
        background-image: url("/img/upload.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100px;
        height: 100%;
        opacity: 0.35;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .header {
        font-size: $font-xl;
    }

    p {
        font-style: italic;
    }

    input {
        cursor: pointer;
        filter: alpha(opacity=0);
        height: 100%;
        margin: 0;
        opacity: 0;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 1;
    }
}
