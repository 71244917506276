@use "theme/variables" as *;

.c-email-edit {

    .content-preview {
        background-color: $white;
        border: 1px solid $grey-5;
        border-radius: 4px;
        height: 100%;
        margin-top: $spacer-2;
        overflow: auto;
        padding: $spacer-2;
        resize: vertical;
        width: 100%;
    }

    .modal-body {
        padding: 24px;
    }

    .refresh-wrapper {
        align-items: flex-end;
        display: flex;
        gap: $spacer-1;

        .field {
            flex: unset;
            margin-bottom: 0;
        }

        .flag {
            height: $spacer-5;
            width: $spacer-6;
        }
    }


    .template {
        margin-right: 10px;
    }
}

.note-editor .note-editable {
    font-family: Calibri, Arial, Helvetica, sans-serif;
    font-size: 14px;
}
