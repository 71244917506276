@use "theme/variables" as *;

.relation-details {

    .overflow-panel {
        max-height: 400px;
        overflow-y: auto;

        .panel {

            .panel-heading {

                span {
                    padding-left: 5px;
                }
            }

            .price-list-refresh-information {
                align-items: center;
                display: flex;
                gap: $spacer-3;
            }
        }
    }
}
