@use "theme/variables" as *;

.c-details {
    max-width: 1200px;

    .c-detail-label {
        column-gap: $spacer-1;
        display: flex;
        flex-direction: row;

        .detail-label {
            display: flex;
            font-weight: 700;
            justify-content: end;
            min-width: 160px;
            text-align: right;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .detail-value {
            display: flex;
            min-width: 160px;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;

            .c-inline-edit {
                width: 100%;
            }
        }
    }
}
