@use "theme/variables" as *;

.c-market-list-pricelists {

    .stats {
        align-items: center;
        display: flex;
        gap: $spacer-1;

        .stat {
            align-items: center;
            color: $info-1;
            display: flex;
            font-weight: 600;

            .c-icon {
                color: $default-3;
            }
        }
    }

    .pricelist-status {
        background: $default-6;
        border: 1px solid $default-4;
        font-family: monospace;
        font-size: $font-xs;
        padding: $spacer-1;
    }
}
