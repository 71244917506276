@use "theme/variables" as *;

.c-dashboard-figures {

    .c-widget-individual-purchase,
    .c-purchase-statistics,
    .c-widget-year-sales,
    .c-sales-statistics,
    .c-widget-individual-sales,
    .c-widget-portal-sales,
    .c-widget-relations.dashboard-widget {

        .text {
            text-align: center;
        }
    }

    .management-panel {

        .c-purchase-statistics.dashboard-widget,
        .c-sales-statistics.dashboard-widget {
            max-height: unset;

            canvas {
                height: 375px;
                width: 100%;
            }
        }
    }
}
