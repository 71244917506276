@use "theme/variables" as *;

.c-sales-order-relation-memo {
    display: inline;

    .sales-order-memo {
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: all 0.2s;
        white-space: nowrap;

        &--active {
            cursor: pointer;
            white-space: pre-line;

            &:hover {
                background-color: $grey-5;
            }
        }

        &:hover {
            background-color: $grey-5;
        }
    }
}
