@use "theme/variables" as *;

.c-list-input {
    display: flex;
    flex-flow: wrap;
    margin-bottom: $spacer-2;

    .list-input {
        margin-bottom: $spacer-1;
        width: 100%;

        .c-field-text {
            margin-bottom: 0;

            .c-button {
                border-radius: 0;
            }
        }
    }

    .unit-list {
        background: $white;
        width: 100%;

        .list-item {
            border: 1px solid $default-4;
            cursor: default;
            display: flex;
            justify-content: space-between;

            &:hover {
                background: $info-5;
            }

            .c-button {
                border-radius: 0;
            }

            .content {
                align-items: flex-start;
                display: flex;
                justify-content: center;
                padding: $spacer-1;
            }
        }
    }
}
