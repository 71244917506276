@use "common/lib/mixins" as *;
@use "theme/variables" as *;

.c-field-code {

    .placeholders {
        display: flex;
        gap: $spacer-1;

        input {
            @include square($spacer-7);

            border-radius: 50%;
            color: $info-2;
            font-size: $font-xl;
            font-weight: 600;
            margin: 0;
            text-align: center;
            /* stylelint-disable-next-line order/properties-alphabetical-order */
            appearance: textfield;

            &::placeholder {
                color: $grey-3;
            }

            &:focus {
                border-color: $info-2;
            }
        }
    }

    &.invalid {

        .placeholders {

            input {
                border-color: $grey-3;

                &.invalid {
                    border: 1px solid $danger-2;
                }
            }
        }
    }

    &.valid {

        .placeholders {

            input {
                border-color: $success-2;
                color: $success-2;
            }
        }
    }

    @media (max-width: $breakpoint-20) {

        .placeholders {
            gap: $spacer-05;

            input {
                @include square($spacer-6);
            }
        }
    }
}
