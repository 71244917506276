@use "theme/variables" as *;

// Define all field component specific styling here for now

.c-field-time,
.c-field-number,
.c-field-percent,
.c-field-money,
.c-field-text {

    input {
        height: $input-height;

        &.invalid {
            border: 1px solid hsl(var(--danger-h) var(--s-1) var(--l-2));
        }

        &.valid {
            border: $validation-valid-border;
        }
    }
}

.c-field-date {
    max-width: calc(50% - #{$spacer-1});
}

.c-field-time {
    max-width: 100px;
}

.c-field-percent,
.c-field-money {
    max-width: 175px;
}

.c-field-checkbox {
    display: flex;
    margin-bottom: $input-margin;

    .control {
        align-items: center;
        gap: $spacer-1;

        label {
            align-items: center;
            display: flex;
            font-weight: 600;
            height: $input-label-height;
            margin: 0;
        }
    }
}

.c-field-select {

    select {
        background: $input-bg;
    }

    &.invalid {

        select {

            &:focus {
                outline: $focus-outline-invalid;
            }
        }
    }

    &:not(.invalid) {

        select {

            &:focus {
                outline: $focus-outline;
            }
        }
    }
}

.c-field-textarea {

    textarea {
        background: $input-bg;
        border: 1px solid $input-border-color;

        &:focus {
            outline: $focus-outline;
        }
    }
}

.c-field-text {

    input {

        &:focus {
            outline: $focus-outline;
        }
    }
}
