@use "theme/variables" as *;

$panel-width: 420px;

@mixin panel-collapsed {
    max-width: $panel-collapsed-width;
    overflow: visible;  // For the manager image at the bottom
    padding: 0;

    .menu {

        .btn-label {
            display: none;
        }

        .c-button {

            svg {
                margin: 0;
                width: $panel-collapsed-width;
            }

            span {
                display: none;
            }

            .context {
                display: flex;
            }
        }
    }

    .nav-item {
        overflow: hidden;
    }

    .logo {
        width: 100%;

        img {
            padding: $spacer-05;
        }

        .version {
            display: none;
        }
    }
}

.c-panel-left {
    align-items: center;
    background: $default-5;
    box-shadow: $shadow-down-d-r;
    display: flex;
    flex-direction: column;
    height: var(--app-height);
    max-width: 225px;
    position: relative;
    transition: max-width 0.25s ease-in-out;
    width: 100%;

    // Force a new stacking context for the shadow to pop over bar-main.
    // Make sure the tooltip is in front of the filter panel, but the
    // sidebar is behind the modal overlay.
    z-index: 1000000;

    .logo {
        align-items: center;
        align-self: flex-start;
        background-color: $white;
        border-bottom: 2px solid $default-4;
        display: flex;
        height: $spacer-6 + $spacer-8;
        position: relative;
        width: 100%;

        > a {
            align-items: center;
            display: flex;
            height: 100%;
            width: 100%;

            img {
                max-height: 100px;
                max-width: 100%;
                padding: $spacer-1;
            }
        }

        .version {
            color: $default-3;
            cursor: help;
            cursor: pointer;
            flex: 1;
            position: absolute;
            right: $spacer-025;
            text-align: right;
            top: $spacer-025;
            user-select: none;

            &:hover {
                color: $info-2;
            }
        }
    }

    .menu {
        display: flex;
        flex-direction: column;
        width: 100%;

        .c-button {

            .btn-label {
                background: $brand-3;
                border-radius: $spacer-4;
                color: $brand-5;
                font-size: $font-xs;
                font-weight: 600;
                padding: $spacer-1;
            }

            &.active {

                .btn-label {
                    background: $brand-4;
                    color: $white;
                }
            }
        }


        hr {
            border-bottom: 1px solid $default-4;
            margin: $spacer-1 0;
        }
    }

    &.active {
        background: $grey-6;
        border-right: 1px solid $grey-4;
        box-shadow: $shadow-down-d-r;
        display: flex;
        flex-direction: column;
        height: var(--app-height);
        left: 0;
        max-width: $panel-width;

        // no horizontal scrollbar during animating collapse
        overflow-x: hidden;

        // Element needs to scale with maximum viewport width.
        padding-left: $panel-collapsed-width;
        position: absolute;
        top: 0;
        transition: max-width 0.3s ease, opacity 0.3s ease;
        width: 100%;

        // (!) Above bar-main
        z-index: 100002;

        &.collapsed {
            max-width: 0;
            overflow: hidden;
        }
    }

    &.collapsed {
        @include panel-collapsed;

        .support-manager {
            background: none;
            padding: 0;

            :hover {
                cursor: pointer;
            }
        }
    }

    @media (min-width: $breakpoint-35) {

        .logo {
            img.collapsed { display: none; }
            img.collapse { display: block; }
        }

        &.collapsed {

            .logo {
                img.collapsed { display: block; }
                img.collapse { display: none; }
            }
        }
    }

    @media (max-width: $breakpoint-35) {

        .logo {

            img.collapse {
                display: none;
            }

            img.collapsed {
                display: block;
            }
        }
    }
}
