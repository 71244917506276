@use "theme/variables" as *;

.c-collection-inline-field {
    display: flex;
    gap: $spacer-05;
    padding: $spacer-1;

    .field {
        justify-content: center;
        margin-bottom: 0;
        min-height: $spacer-5;

        label {
            display: none;
        }

        .help {
            position: absolute;
            top: 0;
        }
    }

    .actions {
        display: flex;
        flex-direction: column;
        gap: $spacer-025;
        justify-content: center;
    }

    &.changed {
        background: $success-5;
    }

    &.invalid {
        background: $danger-5;
    }
}
