@use "theme/variables" as *;

.c-search-discover {
    cursor: pointer;
    flex: 1;
    max-width: 300px;

    .search-bar {
        align-items: center;
        display: flex;

        input {
            background: $info-1;
            border: none;
            border-bottom: 2px solid $default-3;
            border-right: 0;
            color: $info-6;
            max-width: 450px;
            outline: none;
            width: 100%;

            &::placeholder {
                color: $default-3;
                opacity: 1;
            }

            &:focus {
                border-color: $default-4;

                &::placeholder {
                    color: $default-4;
                }
            }
        }

        .btn-cancel {
            margin-left: $spacer-1;

            &[disabled] {
                color: $default-3;
            }

            &:not([disabled]) {
                color: $default-4;

                &:hover {
                    color: $brand-3;
                }
            }
        }
    }

    .autocomplete-suggestions {
        border: none;
        box-shadow: $shadow-down-l;
        display: flex;
        flex-wrap: wrap;
        margin-top: $spacer-05;
        max-height: 800px;
        max-width: 1504px;
        overflow-y: auto;
        resize: none;
        width: unset;

        .search-category {
            display: flex;
            flex: 1;
            flex-direction: column;
            max-height: 400px;
            max-width: 500px;
            min-width: 500px;

            .title {
                align-items: center;
                background: $info-1;
                color: $info-5;
                display: flex;
                font-weight: 600;
                height: $spacer-6;
                min-height: $spacer-6;
                padding-left: $spacer-1;
                text-transform: uppercase;
            }

            .items {
                background: $surface-6;
                overflow-y: scroll;

                .search-item {
                    align-items: center;
                    color: $grey-2;
                    display: flex;
                    font-size: $font-s;
                    gap: $spacer-1;
                    height: $spacer-4;
                    padding: $spacer-1;

                    span {
                        font-weight: 500;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    a {
                        font-weight: 500;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                        &:hover {
                            color: $info-2;
                        }
                    }

                    &:hover {
                        background: $brand-3;
                        color: $brand-5;
                    }
                }
            }
        }
    }
}
