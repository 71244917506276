@use "theme/variables" as *;

.c-move-purchase-order-items {

    .btn-toolbar {
        margin-bottom: 0;
        padding: $spacer-1;
        padding-bottom: 0;
        padding-left: $spacer-3;
        padding-top: $spacer-3;
    }

    .move-actions {
        align-items: start;
        display: flex;
        flex-direction: column;
        gap: $spacer-1;
        justify-content: flex-end;
        width: 100%;

        .row-1 {
            align-items: center;
            display: flex;
            flex-direction: row;
            gap: $spacer-1;
            justify-content: flex-end;
            width: 100%;
        }
    }
}
