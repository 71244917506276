@use "theme/variables" as *;

.c-notifications {
    bottom: 60px;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    gap: $spacer-2;
    position: absolute;
    width: 475px;
    z-index: 10000000000000000;

    @media (max-width: $breakpoint-20) {
        left: 0;
        padding: 0 $spacer-1;
        width: 100vw;
    }
}

