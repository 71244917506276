@use "theme/variables" as *;

table.stats-table {

    td:first-child,
    th:first-child {
        padding-left: 0;
    }

    td,
    th {
        padding: 10px;
    }

    th {
        overflow: hidden;
        white-space: nowrap;
    }

    td.ellipsis {
        max-width: 300px;
    }
}
