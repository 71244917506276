@use "theme/variables" as *;

.c-data-card {
    display: flex;
    flex-direction: column;
    gap: $spacer-025;
    position: relative;
    width: 100%;
    z-index: 10;

    > :nth-child(even) {
        background: $default-6;
    }

    > :nth-child(odd) {
        background: $default-5;
    }

    .entry {
        display: flex;
        padding: $spacer-1;
        width: 100%;

        .entry-label {
            color: $default-1;
            display: flex;
            font-weight: 600;
        }

        &.type-default {

            .entry-label {
                flex: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                svg {
                    color: $surface-3;
                }
            }

            .entry-value {
                color: $default-2;
                flex: 3;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        &.type-textarea {
            background: $white;
            flex-direction: column;

            .entry-value {
                overflow: auto;
                text-overflow: unset;
            }
        }
    }

    &.type-dense {
        gap: 0;

        .entry {
            padding: $spacer-05 $spacer-1;
        }
    }

    &.type-extra-dense {
        gap: $spacer-025;

        > :nth-child(even) {
            background: none;
        }

        .entry {
            padding: $spacer-05 $spacer-025;

            .entry-label {
                flex: unset;
                margin-right: $spacer-05;
            }
        }
    }
}
