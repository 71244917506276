@use "theme/variables" as *;

.c-field-text {
    flex: 1;

    .input-wrapper {
        align-items: flex-end;
        display: flex;
        position: relative;

        input {
            width: 100%;

            + svg {
                color: $grey-3;
                position: absolute;
                right: $spacer-1;
                top: $spacer-4;
            }
        }

        .search-suggestions {
            background: $info-6;
            border: 1px solid $default-3;
            box-shadow: $shadow-down-l;
            max-height: 200px;
            overflow: auto;
            position: absolute;
            top: $input-height;
            width: 100%;
            z-index: 100000;

            .suggestion {
                cursor: pointer;
                padding: $spacer-1;
                z-index: 100001;

                &.selected {
                    background: $info-2;
                    color: $info-5;
                }

                &:hover {
                    background: $info-1;
                    color: $info-6;
                }
            }
        }
    }

    .search-linked {
        align-items: center;
        display: flex;
        flex: 1;

        .linked-label {
            align-items: center;
            background: $info-6;
            border: 1px solid $info-4;
            color: $info-1;
            display: flex;
            flex: 1;
            height: $input-height;
            padding: $spacer-1;
            width: 100%;
        }

        .c-button {
            border-radius: 0;
        }
    }
}
