@use "common/lib/mixins" as *;
@use "theme/variables" as *;

$panel-inner-width: 470px;

.c-layout-landing {
    display: flex;
    overflow: hidden;
    width: 100%;

    .landing-image {
        background-color: $black;
        background-size: cover;
        height: 100vh;
        left: 0;
        opacity: 0;
        position: absolute;
        transition: opacity 1.5s ease;
        width: 100vw;

        &.active {
            opacity: 1;
        }
    }

    .landing-login {
        background-image: url("/common/img/landing-login.jpg");
    }

    .landing-register {
        background-image: url("/common/img/landing-register.jpg");
    }

    .landing-redeem {
        background-image: url("/common/img/landing-redeem.jpg");
    }

    .l-panel {
        align-items: center;
        backdrop-filter: blur(12px);
        background: hsla(var(--info-h) var(--s-1) var(--l-1) / 70%);
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        left: 0;
        max-width: 40%;
        position: absolute;
        width: 100%;
        z-index: 999;

        .header {
            align-items: center;
            display: flex;
            flex-direction: column;
            margin-bottom: $spacer-3;
            max-width: $panel-inner-width;
            position: relative;
            width: 100%;

            .language-selection {
                display: flex;
                gap: $spacer-1;
                height: $icon-l;
                width: $icon-l * 2;

                .fi {
                    height: 100%;
                    opacity: 0.5;
                    transition: opacity 0.3s ease;
                    width: 100%;

                    &:not(.active) {
                        cursor: pointer;
                    }

                    &.active {
                        opacity: 1;
                    }
                }
            }

            .logo {
                align-items: center;
                display: flex;
                position: relative;
                width: 100%;

                img {
                    display: block;
                    max-width: 200px;
                }
            }

            .support-manager {
                @include on-circle(9, $spacer-8 * 2, $spacer-5, 60);
                @include square($spacer-8 * 2);

                background-size: cover;
                border: 3px solid $info-2;
                border-radius: 50%;
                bottom: 50px;
                position: absolute;
                right: 0;
                top: $spacer-2;

                .name {
                    color: $default-3;
                    display: flex;
                    flex-direction: column;
                    font-weight: 600;
                    margin-top: -1 * $spacer-5;
                    text-align: center;

                    span {
                        white-space: nowrap;
                    }

                    span:first-child {
                        color: $default-4;
                    }
                }

                .c-notifications {
                    bottom: $spacer-2;
                    left: $spacer-8 * 2;
                }

                .c-button {
                    @include square($spacer-1 * 4.75);

                    right: 0;
                }
            }

            .sub-header {
                width: 100%;

                h3 {
                    color: $default-3;
                    font-size: $font-xxl;
                    margin-bottom: $spacer-05;
                    margin-top: $spacer-3;
                }

                p {
                    color: $info-3;
                    font-size: $font-l;
                    margin: 0;
                }
            }
        }

        .landing {
            max-width: $panel-inner-width;
        }

        .form {
            // A condensed form, as being used on the landing page.
            background: $white;
            border-radius: $border-radius-d;
            box-shadow: $shadow-down-l;
            display: flex;
            flex-direction: column;
            min-width: $panel-inner-width;
            padding: $spacer-3;
            width: 100%;

            .fieldset {
                background: none;
                border: 0;
                padding: 0;
            }

            .btn-login {
                margin-top: $spacer-2;
                width: 100%;
            }

            h3 {
                color: $info-1;
                font-size: $font-l;
                margin: 0;
                margin-bottom: $spacer-1;
            }

            .fieldset-group {
                // A bit more condensed version of a regular form
                margin-bottom: $spacer-1;
            }

            .field-group {

                .field {
                    flex: 1;
                    min-width: 0;
                }
            }

            .fieldset-label {
                color: $default-2;
                font-size: $font-l;
                margin-bottom: $spacer-1;
                margin-top: $spacer-2;
            }
        }
    }

    .about {
        background: hsla(var(--info-h) var(--s-3) var(--l-1) / 50%);
        border: 1px solid $info-2;
        border-radius: $border-radius-d;
        color: $info-5;
        line-height: 1.4;
        margin-top: $spacer-2;
        padding: $spacer-2 $spacer-3;
    }

    .stepper-form {
        margin-bottom: $spacer-3;

        .step {
            margin-bottom: $spacer-3;

            &.step-unknown {
                // No forward/back buttons; just a submit.
                margin-bottom: 0;
            }

            .field {
                margin-bottom: $spacer-2;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .field-group {
                margin-bottom: $spacer-2;

                .field {
                    margin-bottom: 0;
                }
            }

            .field-readonly-list {
                max-height: 250px;
                overflow: scroll;
            }
        }
    }


    @media (max-width: $breakpoint-35) {
        flex-direction: column;

        .stepper-form {
            overflow: scroll;
        }

        .step-3 {
            overflow: scroll;

            + .c-button-group {
                margin-top: $spacer-2;
            }
        }

        .about {
            display: none;
        }

        .l-panel {
            max-width: 60%;
        }

        .c-image {
            display: none;
        }
    }

    @media (max-width: $breakpoint-20) {

        .l-panel {
            backdrop-filter: blur(2px);
            max-width: unset;
            padding: $spacer-1;

            .header {

                .sub-header {
                    margin-top: $spacer-2;

                    h3 {
                        font-size: $font-xl;
                    }

                    p {
                        font-size: $font-d;
                    }
                }
            }

            .form {
                min-width: unset;
            }
        }
    }
}

html.embedded {

    body,
    .app {
        // The embedded version of the portal registration form, as being used
        // on movingspirits.eu, needs to expand height so the correct body
        // height is propagated to the parent iframe. A CORS iframe can only
        // adjust height by passing messages.
        height: auto;
        width: 100%;

        .c-layout-landing {
            min-width: 400px; // form gets more width when the next step doesn't fit; viceversa doesn't
            padding: $spacer-1; // outline & borders at the edges of the iframe
        }
    }
}
