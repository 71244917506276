@use "theme/variables" as *;

.c-request-for-proposal {

    .biddings {
        max-height: 500px;
        overflow-y: scroll;

        .bid-line {
            display: flex;


            .field-group {
                margin-right: $spacer-2;
            }
        }
    }
}
