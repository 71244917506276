@use "theme/variables" as *;

.c-upsert-spli {

    > .c-button-group {
        padding: 0 $spacer-2;
    }

    .c-search-bar {
        max-width: unset;
    }

    .fieldset {
        margin-bottom: 0;
    }

    .spli-status {
        align-items: center;
        display: flex;
        font-weight: 600;
        gap: $spacer-1;
        padding: $spacer-1;
        transition: background 0.3s ease;
        width: 100%;

        &.info {
            background: $info-4;
            color: $info-2;
        }

        &.success {
            background: $success-4;
            color: $success-2;
        }

        &.warning {
            background: $warning-5;
            color: $warning-2;
        }
    }

    .quantity-switcher {
        display: flex;

        label {
            width: 100%;
        }

        .field-wrapper {
            align-items: flex-end;
            border: 1px solid;
            border-color: $info-4;
            display: flex;

            .c-button.variant-action {
                border: 0;
                border-radius: 0;
            }

            .c-field-text {
                max-width: 70px;
            }

            .c-field-money,
            .c-field-text {

                input {
                    background: $info-5;
                    border: 0;
                    border-right: 1px solid;
                    color: $info-1;
                }
            }

            .c-field-money {

                select {
                    background: $info-1;
                    border: 5;
                    color: $info-6;
                }
            }
        }

        &.disabled {

            .field-wrapper {
                border-color: $grey-5;

                .c-field-money,
                .c-field-text {

                    input {
                        border-color: $grey-5;
                        color: $grey-3;
                    }
                }
            }
        }
    }

    .gtin-field {
        display: flex;
        flex: 0.6666;
        flex-direction: row;

        .c-button {
            border-radius: 0 !important;
        }
    }

    .country-select {
        display: flex;

        select {
            width: 100%;
        }

        label {
            width: 100%;
        }

        .field-wrapper {
            align-items: flex-end;
            display: flex;

            .c-button.variant-action {
                border-radius: 0;
            }
        }
    }
}
