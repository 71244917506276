@use "theme/variables" as *;

.c-dropdown {
    position: relative;

    &.right {

        .dropdown-body {
            right: 0;
            top: 100%;
        }
    }

    .dropdown-body {
        background: $grey-6;
        border: 1px solid var(--grey-6);
        box-shadow: $shadow-down-l;
        display: none;
        max-height: 50vh;
        position: absolute;
        text-align: left;
        z-index: 1000000;

        .dropdown-body-header {
            align-items: center;
            background: $info-2;
            border-bottom: 2px solid $default-4;
            color: $default-5;
            display: flex;
            flex-direction: row;
            font-size: $font-d;
            justify-content: space-between;
            padding: $spacer-1 $spacer-1 * 1.5;

            svg {
                color: $default-5;
            }

            span {
                flex: 1;
                margin-left: $spacer-05;
            }
        }

        &.left {
            right: 100%;
            top: 0;
        }

        &.list {
            font-size: $font-d;
            min-width: 150px;

            .item {
                align-items: center;
                background: none;
                border: none;
                color: var(--grey-2);
                cursor: pointer;
                display: flex;
                padding: $spacer-1 * 1.5 $spacer-1;
                text-align: left;
                width: 100%;

                &:hover {
                    background: var(--info-6);
                }

                svg {
                    color: var(--info-2);
                    margin-right: $spacer-1;
                }

                &[disabled] {
                    background: var(--grey-6);
                    color: var(--grey-4);
                    cursor: not-allowed;

                    &:hover a {
                        color: var(--grey-4) !important;
                    }

                    a {
                        color: var(--grey-4);
                    }
                }

                &.active {
                    background: var(--info-6);
                    cursor: default;
                }
            }
        }
    }

    &.active {

        .dropdown-body {
            display: block;
        }
    }
}
