@use "theme/variables" as *;

.field.c-filter-toggle {
    background: none;
    flex-direction: row-reverse;
    gap: $spacer-1;
    justify-content: flex-end;
    margin-bottom: $spacer-2;

    label {
        color: $grey-3;
        transition: color 0.3s ease-in-out;
    }

    &.active {

        label {
            color: $brand-3;
        }
    }
}
