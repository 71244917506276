@use "theme/variables" as *;

@mixin filter {
    cursor: pointer;
    font-weight: 600;
    margin-bottom: $spacer-1;

    .title {
        align-items: center;
        display: flex;
        height: $table-header-height;
        margin-bottom: $spacer-1;
        padding: $spacer-1;
        text-decoration: none;

        span {
            margin-left: $spacer-05;
            margin-right: $spacer-1;
            margin-top: 2px;
        }
    }

    .items {
        max-height: 250px;
        overflow-y: scroll;
        padding: 0 $spacer-1;
    }

    &.collapsed {

        .title {
            background: $default-6;
            color: $default-2;
        }
    }

    &:not(.collapsed) {

        .title {
            background: $info-2;
            color: $info-5;
        }
    }
}

.c-checkbox-filter {
    @include filter;

    .c-field-checkbox {
        margin-bottom: $spacer-05;

        .control {

            label {
                font-weight: 400;
            }
        }
    }
}

.c-filter-preset-button {
    display: flex;
    gap: $spacer-1;

    .btn-group {
        display: flex;
    }
}

.c-checkbox-group {
    @include filter;

    // Merge with duplicate checkboxgroup.ls later

    .c-field-checkbox {
        margin-bottom: $spacer-05;

        .control {

            label {
                font-weight: 400;
            }
        }
    }
}
