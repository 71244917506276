@use "theme/variables" as *;

@mixin variant($hue) {
    // background: hsla(var(--#{$hue}-h) var(--s-2) var(--l-1));
    // border: 1px solid hsla(var(--#{$hue}-h) var(--s-2) var(--l-3));
    // color: hsl(var(--#{$hue}-h) var(--s-2) var(--l-2));
}

.c-item-tags {
    align-items: flex-start;
    display: flex;
    gap: $spacer-025;

    .c-tag-label {
        // border-radius: $spacer-1;
        font-size: $font-xxs;
        font-weight: 600;
        line-height: $spacer-2;

        // padding: $spacer-025 $spacer-1;
        text-align: center;
        white-space: nowrap;
        width: auto;

        &.category { @include variant("info"); }
        &.tax { @include variant("danger"); }
        &.packaging { @include variant("default"); }
        &.pack-size { @include variant("success"); }
        &.damages { @include variant("warning"); }
        &.general { @include variant("default"); }
        &.bbd { @include variant("brand"); }
        &.cpp { @include variant("grey"); }
    }
}
