@use "theme/variables" as *;

.c-widget-relations.dashboard-widget {
    margin-top: $spacer-5;
    max-height: unset;
    overflow: auto;

    .widget-body {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .top-relations {
            min-height: 200px;
        }

        .charts-wrapper {
            display: flex;
            margin-top: $spacer-2;

            .relation-map {
                aspect-ratio: 21 / 5;
                outline: 0;
            }
        }
    }

    .relation-wrapper {
        display: flex;
        overflow: auto;
        width: 100%;
    }
}
