@use "theme/variables" as *;

.product_photos {
    padding: 16px 24px;

    .download-buttons {

        button + button {
            margin-left: 10px;
        }
    }

    .download-all {
        margin-top: 10px;
    }

    .btn-dowload {
        margin-right: 8px;
    }
}
