@use "theme/variables" as *;

.c-market-list-pricelist-items {

    .c-spli-resolved-detail-row {
        max-height: 100%;
        transition: all 10.3s ease;

        td {
            max-height: 100%;
        }

        &.collapsed {
            max-height: 0;

            td {
                border: none;
                max-height: 0;
                padding: 0;
            }
        }
    }

    .row-details {
        max-width: 100%;
        overflow: hidden;
    }
}
