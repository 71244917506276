@use "theme/variables" as *;

.c-changed {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    gap: $spacer-05;

    .current {
        font-size: $font-xl;
        line-height: $font-l;
        white-space: nowrap;

        .price-hint {
            font-size: $font-d;
            font-weight: 600;
            margin-top: -1 * $spacer-05;
            position: absolute;
        }
    }

    .previous {
        font-size: $font-d;
        position: relative;

        &::before {
            border-top: 1px solid;
            content: "";
            left: 0;
            position: absolute;
            right: 0;
            top: 50%;
            transform: rotate(-10deg);
        }
    }

    .validity {
        color: $brand-3;
        font-size: $font-xs;
    }

    &.type-warning {

        .current {
            color: $warning-3;
        }
    }

    &.type-brand {

        .current {
            color: $brand-3;
        }
    }


    abbr {
        color: $grey-3;
    }

    time {
        color: $grey-3;
        font-size: x-small;
    }
}
