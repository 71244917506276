@use "theme/variables" as *;

.c-link {
    font-weight: 600;

    &.type-info {
        color: $info-2;

        &:hover {
            color: $brand-3;
        }
    }

    &.type-success {
        color: $success-2;

        &:hover {
            color: $success-3;
        }
    }
}
