@use "theme/variables" as *;

.c-search-input,
.c-search-bar {
    // Visually the same components; search_input.ls &
    display: flex;
    flex: 1;
    max-width: 400px;
    width: 100%;

    .autocomplete-suggestions {
        max-width: 600px;
    }

    input {
        border-right: 0;
        flex: 1;

        &:focus {
            outline: $focus-outline;
        }
    }

    .btn {
        height: $input-height;
    }
}

.c-dropdown-select {

    select {
        width: 100%;
    }
}

.c-search-bar {
    display: flex;
    flex-direction: column;

    .control {
        display: flex;
    }
}

.c-multi-result-searchbar {
    max-width: 600px;
    min-width: 400px;
    width: 100%;

    .selected-terms {
        display: flex;
        flex-wrap: wrap;
        gap: $spacer-05;
        min-height: $spacer-3;
        padding: $spacer-025;

        .label {
            align-items: center;
            background: hsl(var(--info-h) var(--s-3) var(--l-1));
            cursor: pointer;
            display: flex;
            gap: $spacer-05;
        }
    }
}

.autocomplete-suggestions {
    background: $surface-6;
    border: 1px solid $input-border-color;
    cursor: default;
    margin-top: $input-height - 1px;
    max-height: 300px;
    overflow: auto;
    position: absolute;
    resize: horizontal;
    width: 100%;
    z-index: 1000;
}

.autocomplete-suggestion {
    align-items: center;
    display: flex;
    height: $input-height;
    overflow: hidden;
    padding: $spacer-1;
    white-space: nowrap;
}

.autocomplete-suggestion.highlighted {
    background: hsl(var(--info-h) var(--s-3) var(--l-1));
    color: hsl(var(--info-h) var(--s-3) var(--l-5));
}

.btn-group {

    .multiselect.dropdown-toggle.btn {
        align-items: center;
        border-radius: 0;
        box-shadow: none;
        color: $input-color;
        cursor: default;
        display: flex;
        height: $input-height;
        min-width: 100%;
        width: 100%;

        .multiselect-selected-text {
            flex: 1;
        }

        &:not([disabled]) {
            background: $input-bg;
            border: 1px solid $input-border-color;

            &:hover,
            &:focus {
                border-color: $input-border-color;
                color: $input-color;
                outline: $focus-outline;
            }
        }

        &[disabled] {
            background: $input-disabled-bg;
            color: $input-disabled-color;
            cursor: not-allowed;

            &:hover,
            &:focus {
                border-color: $input-disabled-bg;
                color: $input-color;
                outline: $input-disabled-border;
            }
        }
    }

    &.open {

        .multiselect.dropdown-toggle.btn {
            background: $input-bg;
            border-color: $input-border-color;
            outline: $focus-outline !important;
        }
    }

    .multiselect-container {
        border: 1px solid $input-border-color;
        border-radius: 0;
        box-shadow: $shadow-down-l;
        max-height: 400px;
        overflow-y: scroll;
        padding: 0;

        .filter {

            .input-group {
                align-items: flex-end;
                background: $surface-6;
                display: flex;
                height: $input-height + 1px;
                width: 100%;

                .input-group-addon {
                    align-items: center;
                    border: 0;
                    border-radius: 0;
                    display: flex;
                    flex: 1;
                    height: $input-height;
                    justify-content: center;
                    width: $spacer-5;

                    i {
                        top: 0;
                    }
                }

                .multiselect-search {
                    box-shadow: none;
                    height: $input-height;
                    padding: 0 $spacer-1;
                }

                .input-group-btn {
                    display: flex;
                    height: $input-height;
                    width: $input-height;

                    .multiselect-clear-filter {
                        align-items: center;
                        background: $surface-6;
                        border: 0;
                        border-bottom: 1px solid hsl(var(--surface-h) var(--s-1) var(--l-4));
                        border-radius: 0;
                        color: $surface-2;
                        display: flex;
                        justify-content: center;


                        &:active,
                        &:focus,
                        &:hover {
                            background: $surface-5;
                            border-color: $input-border-color;
                            color: $surface-1;
                        }
                    }
                }
            }
        }

        li {
            margin: 0;

            a {
                cursor: pointer;
                padding: 0;
                vertical-align: middle;

                &:hover {
                    background: $info-2;
                    color: $info-5;
                }

                label {
                    align-items: center;
                    cursor: pointer;
                    display: flex;
                    font-weight: 400;
                    height: $input-height;
                    line-height: $spacer-4;
                    margin: 0;
                    padding: 0 $spacer-05;
                    vertical-align: middle;
                }

                input {
                    display: none;
                }
            }

            &.active {

                a {
                    background: $info-2;

                    label {
                        color: $info-5;
                    }
                }
            }
        }
    }
}

.c-field-dropdown-search {
    display: flex;
    min-width: 200px;

    > .btn-group {
        width: 100%;
    }
}

.c-field-dropdown-search-multi {
    cursor: not-allowed;
    display: flex;

    &.disabled {

        .btn-group {

            .multiselect.dropdown-toggle.btn {
                background: $input-disabled-bg;
                border-color: $input-disabled-border;

                &:focus {
                    outline: none !important;
                }
            }
        }
    }
}

.c-inline-edit {
    display: flex;
    white-space: break-spaces;

    .edit-text {
        width: 100%;

        textarea {
            width: 100%;
        }
    }
}
