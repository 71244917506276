@use "theme/variables" as *;

.c-panel {
    background: $surface-6;
    border: 1px solid $input-border-color;
    margin-bottom: $spacer-1;
    min-width: fit-content;
    width: fit-content;

    .panel-heading {
        background: $default-5;
        display: flex;
        gap: $spacer-2;
        height: $spacer-6;
        padding: 0 $spacer-2;

        .panel-title {
            align-items: center;
            color: $info-1;
            display: flex;
            flex: 1;
            font-weight: 600;
            gap: $spacer-05;

            .toolbar {
                margin-left: $spacer-2;
            }
        }
    }

    .panel-body {
        margin: $spacer-2;

        td {
            border-bottom: 1px solid $input-border-color;
        }

        .border-cell {
            border-left: 1px solid $input-border-color;
            padding-left: $spacer-2;
        }

        .panel_header_row {
            border-bottom: 3px solid $input-border-color;

            th {
                padding-bottom: $spacer-1;
                padding-top: $spacer-1;
            }
        }
    }

    .toolbar {
        align-items: center;
        display: flex;
    }

    &.collapsed {

        .panel-body {
            display: none;
        }
    }

    &.collapsible {

        .panel-heading {
            cursor: ns-resize;
            transition: all 0.3s ease;

            &:hover {
                background: $default-4;
                color: $info-5;
            }
        }
    }
}
