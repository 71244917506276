@use "theme/variables" as *;

.c-widget-year-sales.dashboard-widget {
    max-height: unset;

    .widget-body {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;


        .c-widget-turnover-margin {
            align-items: center;
            display: flex;
            justify-content: center;

            .gauge {
                align-items: center;
                color: $info-2;
                display: flex;
                flex-direction: column;
                font-weight: 600;

                canvas {
                    min-height: 175px;
                }
            }
        }

        .figures {
            align-items: center;
            display: flex;
            justify-content: center;
            margin-top: $spacer-4;
        }
    }
}

