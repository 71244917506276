@use "theme/variables" as *;

.offer {

    .btn-toolbar {

        .checkbox-inline {
            margin-top: 5px;
        }
    }

    table > tbody.table-row.offer-item__is-hidden {
        background-color: $grey-5;

        &:hover {
            background-color: $grey-4;
        }
    }
}

.approval {

    table.table.search-table.clickable {

        > tbody.table-row {

            &.approval-item--is-rejected {
                background-color: $danger-5;

                &:hover {
                    background-color: $danger-4;
                }
            }

            &.approval_danger {
                background-color: $danger-3;
            }
        }
    }
}
