@use "theme/variables" as *;

.c-widget-notifications.dashboard-widget {
    margin-bottom: 0;
    max-height: unset;

    .widget-title {
        display: flex;
        gap: $spacer-1;
        position: relative;
    }

    .notifications-wrapper {
        display: flex;
        gap: $spacer-1;

        .c-notifications-filter {
            flex: 5;
            max-width: 300px;
            min-width: 300px;
            width: 300px;

            .options {
                cursor: pointer;

                .option {

                    a {
                        align-items: center;
                        color: $surface-2;
                        display: flex;
                        gap: $spacer-1;
                        height: $spacer-6;
                        padding: $spacer-1;
                        width: 100%;
                    }

                    &:hover,
                    &.active {

                        a {
                            background: $info-2;
                            color: $info-5;
                        }
                    }
                }
            }

            .filter-option {
                align-items: center;
                display: flex;
                justify-content: space-between;
                margin-bottom: $spacer-05;

                .c-field-checkbox {
                    margin-bottom: 0;

                    label {
                        font-weight: 400;
                    }
                }

                .label {
                    align-items: center;
                    display: flex;
                    height: $spacer-3;
                    justify-content: center;
                    width: $spacer-3;
                }
            }
        }

        .c-notifications-table {
            width: 100%;

            .actions {
                align-items: center;
                display: flex;
                gap: $spacer-1;
                height: $input-height;
                padding: 0 $spacer-1;

                .c-field-checkbox {
                    margin: 0;
                }
            }

            .c-collection-table {
                max-height: calc(100vh - 250px);
                overflow: auto;
            }
        }
    }
}
