@use "theme/variables" as *;

.c-panel-context {

    .c-workflow-pricelist {
        display: flex;
        flex-direction: column;
        gap: $spacer-1;
        padding: $spacer-1;
        width: 100%;

        .pricelist-wrapper {
            display: flex;

            .field-group {
                margin: 0;
            }
        }

        .setup-stats {

            .c-data-card {

                .entry-label {
                    color: $grey-3;

                    .selected {
                        color: $info-1;
                    }
                }

                .entry-value {
                    font-style: italic;
                }
            }

            .columns {
                display: flex;
                flex-direction: column;
                padding: 0 $spacer-2;

                .column {
                    padding: 0;

                    &.selected {
                        color: $info-1;
                    }
                }
            }

            .checklist {
                background: $default-5;
                border: 1px solid $default-4;
                display: flex;
                flex-direction: column;
                gap: $spacer-05;
                margin-top: $spacer-1;
                padding: $spacer-1;

                .check {
                    align-items: center;
                    display: flex;
                    gap: $spacer-1;
                }
            }

            .match-results {
                background: $default-5;
                border: 1px solid $default-4;
                margin: $spacer-1 0;
                padding: $spacer-1;

                .header {

                    .title {
                        font-size: $font-l;
                        font-weight: 600;
                    }
                }

                .preview-failure {
                    align-items: center;
                    display: flex;
                    gap: $spacer-05;
                }

                .samples {
                    display: flex;
                    justify-content: space-between;
                    padding: $spacer-1 0;
                }
            }

            .modifiers {
                background: $default-5;
                margin-top: $spacer-1;
                padding: $spacer-1;
            }
        }
    }

    &.collapsed .c-workflow-pricelist {
        padding: 0;

        .setup-stats {

            .checklist {

                .check {
                    justify-content: center;

                    span {
                        display: none;
                    }
                }
            }
        }

        .c-button-group {

            .c-button {

                span {
                    display: none;
                }
            }
        }
    }
}
