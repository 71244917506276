@use "theme/variables" as *;

.c-ranking {
    background: $default-6;
    border: 2px solid $default-4;
    display: flex;
    flex-direction: column;
    gap: $spacer-05;
    min-width: $spacer-6;
    padding: $spacer-05;
    width: $spacer-6;

    span {
        color: $info-1;
        font-size: $font-xs;
        font-weight: 600;
        text-align: center;
    }
}
