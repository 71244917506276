@use "theme/variables" as *;

.content-preview {
    background-color: $grey-6;
    border: 1px solid $grey-5;
    border-radius: 4px;
    height: 400px;
    height: 300px;
    margin-bottom: 16px;
    overflow: auto;
    padding: 8px;
    resize: vertical;
}

.ellipsis:hover span .fa-file-excel {
    padding-right: 0;
}
