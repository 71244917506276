@use "theme/variables" as *;

.c-collection-columns {

    &.c-collection-items {
        width: 100%;

        .item {
            color: $info-5;
            display: flex;
            font-weight: 600;
            margin: 0;
            width: 100%;

            &,
            &:hover,
            .cells:hover,
            .cells .cell.cell-status {
                background: $info-1;
            }

            .cell {
                font-size: $font-s;
            }
        }
    }
}
