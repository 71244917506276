@use "theme/variables" as *;

.c-spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;

    & .path {
        animation: dash 1.5s ease-in-out infinite;
        stroke: currentcolor;
        stroke-linecap: round;
    }

    &.view-spinner {
        color: $brand-3;
        height: $spacer-5;
        width: $spacer-5;
    }

    &.type-default {
        color: hsl(var(--default-h) var(--s-1) var(--l-3));

        *.active,
        &:hover {
            color: hsl(var(--info-h) var(--s-1) var(--l-1));
        }
    }

    &.type-brand {
        color: hsl(var(--brand-h) var(--s-3) var(--l-3));
    }

    &.type-success {
        color: hsl(var(--success-h) var(--s-3) var(--l-1));
    }

    &.type-info {
        color: hsl(var(--info-h) var(--s-3) var(--l-2));
    }

    &.type-warning {
        color: hsl(var(--warning-h) var(--s-3) var(--l-3));
    }

    @keyframes rotate {

        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes dash {

        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }

        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }

        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
    }
}
