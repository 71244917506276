@use "theme/variables" as *;
@use "common/lib/mixins" as *;

.c-panel-context {
    align-items: center;
    background: $grey-6;
    box-shadow: $shadow-down-d-l;
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: $panel-width-l;
    overflow: hidden;
    position: relative;
    transition: max-height 0.3s ease, max-width 0.3s ease;
    width: 100%;
    z-index: 1000;

    &.minimizable {
        max-width: $panel-width-l;
    }

    &:not(.minimizable) {
        max-width: $panel-width-l;
    }

    header {
        height: $spacer-8;

        .panel-actions {
            align-items: flex-end;
            display: flex;
            gap: $spacer-1;
            padding-right: $spacer-1;
        }
    }

    .content {
        align-items: center;
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow-y: auto; // makes content within scroll when needed
        width: 100%;

        > .fieldset {
            padding: $spacer-1;
            width: 100%;
        }

        > .c-button-group {
            padding: $spacer-1;
            width: 100%;
        }

        .c-sales-order-items {
            flex: 1;
            overflow-y: scroll;
            width: 100%;
        }

        .c-tabs {
            width: 100%;

            .tabs-container {
                width: 100%;

                .panels {
                    width: 100%;
                }
            }

            .c-tab-panel {
                margin: 0;
                padding: 0;

                .c-collection-view {
                    padding: 0;
                }
            }

            .c-collection-items {
                width: 100%;
            }
        }


        .incentives {

            .c-field-select {
                padding: 0 $spacer-1;
            }
        }

        .vouchers {
            display: flex;
            flex-direction: column;
            margin-top: $spacer-1;
            padding: 0 $spacer-1;
            width: 100%;

            .item {
                display: inline-flex;
                gap: $spacer-1;
            }
        }

        .toggle {
            margin-top: $spacer-2;
        }
    }

    .toggle {
        @include toggle;

        &:not(.collapsed) {
            animation: 0.75s ease toggle-panel-uncollapse;
        }
    }

    &.collapsed {
        padding: 0;

        .workflow {
            justify-content: center;
            padding: $spacer-1 0;

            .steps {
                gap: $spacer-1;
            }
        }

        &.minimizable {
            max-width: $panel-collapsed-width;

            header {
                padding: 0;

                .title {
                    justify-content: center;
                    width: 100%;

                    svg {
                        margin: 0;
                    }

                    span {
                        display: none;
                    }
                }
            }
        }

        &:not(.minimizable) {
            box-shadow: none;
            max-width: 0;
            min-width: 0;
        }

        .panel-actions {
            display: none;
        }

        .toggle {
            animation-play-state: unset;
            left: 0;
            position: relative;

            &.disabled {
                display: block;
            }

            &.collapsed {
                animation-duration: 0.75s;
                animation-iteration-count: 1;
                animation-name: toggle-panel-collapse;
            }
        }
    }

    &:not(.collapsed) {

        .toggle {
            bottom: $spacer-1;
            left: $spacer-1;
            position: absolute;
        }

        .total-container-collapsed {
            display: none;
        }
    }
}
