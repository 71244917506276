@use "theme/variables" as *;

.c-hidden-information {
    gap: $spacer-1;

    .fieldset-group {
        margin-bottom: 0;

        .fieldset {
            display: flex;
        }
    }

    .hidden-items {
        display: flex;
        flex-direction: column;
        gap: $spacer-1;

        .hidden-item {
            align-items: center;
            display: flex;
            gap: $spacer-1;

            > div:first-child {
                flex: max-content;
            }
        }
    }
}
