@use "theme/variables" as *;

.c-stock-context {
    align-items: center;
    display: flex;
    flex-direction: column;

    // gap: $spacer-1;
    justify-content: flex-end;
    width: 100%;

    .stat {
        display: flex;
        flex-direction: column;
        width: 100%;

        &.type-default {

            .header {
                // background: $default-5;
            }
        }

        &.type-info {

            .header {
                // background: $info-5;
                // color: $info-1;
            }
        }

        &.type-success {

            .header {
                // background: $success-5;
                // color: $success-1;
            }
        }
    }

    .header {
        align-items: center;

        // background: $info-5;
        display: flex;
        font-weight: 600;

        // padding: $spacer-1;
    }

    .value {
        font-weight: 500;

        // padding: $spacer-1;
    }
}
