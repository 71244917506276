@use "theme/variables" as *;

.c-panel-bulk {
    $disabled-color: $grey-4;
    $disabled-bg: $grey-6;

    display: flex;
    flex-direction: column;
    max-width: $panel-filters-width;
    padding-top: 0;
    position: relative;
    width: 100%;


    .scroll-container {

        .bulk-control {
            align-content: end;
            display: flex;
            flex-direction: row;
            width: 100%;

            .c-field-select {
                flex-grow: 100;
            }
        }
    }

    .c-panel-bulk-active {
        $disabled-color: $grey-4;
        $disabled-bg: $grey-6;

        display: flex;
        flex-direction: column;
        max-width: $panel-filters-width;
        padding-top: 0;
        position: relative;
        width: 100%;
    }
}
