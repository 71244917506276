@use "theme/variables" as *;

.c-invoice-order {

    .field-readonly {

        .field {
            margin-bottom: $spacer-1;
        }
    }

    .confirmed {
        color: $success-2;
        white-space: pre-line;
    }

    .confirming {
        font-weight: 600;
        white-space: pre-line;
    }

    .unconfirmed {
        color: $grey-3;
        white-space: pre-line;
    }
}
