@use "theme/variables" as *;

.c-market-setup-columns {
    position: relative;

    .c-collection-header {
        align-items: flex-start;
        background: $success-4;
        padding-bottom: $spacer-05;

        .c-collection-status {
            align-items: center;
            background: $info-2;
            color: $info-6;
            display: flex;
            height: 100%;
            padding: unset;
            position: absolute;
            right: 0;
            top: 0;
            width: var(--coll-status-width);

            .indicator {
                align-items: center;
                display: flex;
                flex-direction: column;
                font-size: $font-s;
                height: 100%;
                justify-content: center;
                text-overflow: auto;
                width: 100%;
            }

            .c-icon {
                display: none;
            }
        }

        .c-collection-items {

            .item {
                background: unset;
                margin-bottom: 0;

                .cells {
                    background: none;

                    .cell {
                        padding-bottom: 0;
                        padding-top: 0;
                    }
                }
            }
        }
    }
}
