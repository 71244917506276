@use "theme/variables" as *;

.c-collection-header {
    align-items: center;
    background: $grey-6;
    box-shadow: $shadow-down-d-l;
    display: flex;
    flex-direction: column;
    margin-bottom: $spacer-2;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;

    .actions {
        align-items: center;
        display: flex;
        gap: $spacer-1;
        height: $spacer-8;
        padding: 0 $spacer-2;
        width: 100%;

        .c-dropdown > .c-button.variant-menu,
        > .c-button.variant-menu {
            height: $spacer-8;
            width: $spacer-8;
        }
    }
}
