@use "theme/variables" as *;

.c-read-more-less-toggle {
    display: flex;
    flex-flow: row;

    &.action {
        cursor: pointer;
    }

    &.disabled {
        cursor: not-allowed;
        opacity: 0.7;
    }

    .contents {
        column-gap: $spacer-05;
        display: flex;
        flex: 1;
        flex-flow: column;
        flex-wrap: wrap;
        row-gap: $spacer-05;

        .header {
            display: flex;
            flex: 1;
            flex-flow: row;

            .icon {
                color: $info-2;
                flex: 0.2;
            }

            .summary {
                flex: 0.8;
            }
        }

        .body {
            flex: 1;
        }

        .children {
            flex: 1;
        }
    }
}
