@use "theme/variables" as *;

.c-checkbox-group {

    .no-children {
        font-style: normal;
        font-weight: 400;
    }

    .has-children {
        font-style: italic;
        font-weight: 600;

        > .item.selected {

            + .sub-items {

                input[type="checkbox"]:checked {
                    background: $brand-5;
                    border-color: $brand-3;

                    &::after {
                        border-color: $brand-3;
                    }
                }
            }
        }
    }


    .sub-items {
        margin-bottom: $spacer-05;
        margin-left: $spacer-1 * 1.5;
    }
}

