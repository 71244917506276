@use "theme/variables" as *;

.c-offer-relation-dashboard {

    .stats-group-wrapper {
        display: flex;

        .filter-group-wrapper {
            margin-right: $spacer-3;

            .c-filter-group {
                align-items: start;
                gap: $spacer-05;

                .relation-search-bar {
                    align-items: end;
                    column-gap: $spacer-1;
                    display: flex;
                }
            }
        }
    }
}
