@use "theme/variables" as *;

.c-product-photo-list {

    .c-product-photo-item {
        border: 1px solid $default-5;
        display: flex;
        margin-bottom: $spacer-1;
        padding: $spacer-05;

        .photo-container {
            background: $default-4;
            display: flex;
            flex-direction: column;

            .controls {
                align-items: center;
                display: flex;
                justify-content: space-between;
            }
        }

        img {
            width: 275px;
        }

        .move-target {
            color: $info-1;
            cursor: ns-resize;
        }
    }


    .product-photo {

        &:nth-child(odd) {
            background: $default-5;
        }

        &:nth-child(even) {
            background: $default-6;
        }
    }
}
