@use "theme/variables" as *;

.c-panel-left {

    .profile-options {
        align-items: center;
        display: flex;
        gap: $spacer-2;

        * {
            margin: 0;
        }
    }
}

.navbar-icon {
    color: $surface-4;
    cursor: pointer;
    font-size: $icon-d;
    line-height: unset;
    top: 0;

    &:hover {
        color: $info-5;
    }
}
