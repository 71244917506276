@use "theme/variables" as *;

.c-widget-individual-purchase {

    .widget-body {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }
}
