@use "theme/variables" as *;

.analysis-mwah-color {
    color: $warning-25;
}

.analysis-bad-color {
    color: $danger-25;
}

.analysis-good-color {
    color: $success-25;
}
