@use "common/lib/mixins" as *;
@use "theme/variables" as *;

.c-field-media-upload {

    .media-area {
        align-items: center;
        background: $info-5;
        border: 2px solid $info-4;
        display: flex;
        height: 150px;

        .snapshot {
            align-items: center;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            width: 100%;

            svg {
                @include square($icon-xxl);

                color: $info-3;
            }

            p {
                color: $info-3;
            }

            &.contains-image {

                p,
                svg {
                    display: none;
                }
            }
        }

        .actions {
            background: $info-2;
            display: flex;
            flex-direction: column;
            gap: $spacer-05;
            height: 100%;
            justify-content: center;
            padding: $spacer-05;
        }
    }
}
