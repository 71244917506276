@use "theme/variables" as *;

.c-editable-relations-list {

    .relation-details {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: $spacer-05;
        row-gap: $spacer-05;
    }
}
