@use "theme/variables" as *;

.c-form {
    display: flex;
    flex-direction: column;

    .c-form-group {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        width: 100%;

        .field-label,
        .field-value {
            width: 100%;
        }

        .field-value {
            max-width: 700px;
        }
    }

    .btn {
        max-width: 500px;
    }
}
