@use "theme/variables" as *;

.c-modal-overlay {
    animation: modal-fade-in 0.3s;
    background-color: $grey-1;
    bottom: 0;
    left: 0;
    opacity: 0.5;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10000000;
}

@keyframes modal-fade-in {
    from { opacity: 0; }
    to { opacity: 0.5; }
}

.l-modal-container {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10000001;
}

.c-modal {
    animation: modal-slide-in 0.3s ease-out;
    filter: drop-shadow(0 8px 8px hsla(var(--grey-h) var(--s-1) var(--l-1) / 50%));
    max-width: 1000px;
    width: 60%;

    .c-modal-title {
        background: $info-1;
        border-bottom: 1px solid $grey-5;
        border-top-left-radius: $spacer-2;
        border-top-right-radius: $spacer-2;
        color: $default-5;
        padding: 15px;

        h4 {
            line-height: 1.4;
            margin: 0;
        }

        button.close {
            color: $default-5;
            float: right;
            font-size: 21px;
            font-weight: 700;
            opacity: 1;
            text-shadow: 0 1px 0 $grey-6;
        }

        &::after {
            clear: both;
        }
    }

    .c-modal-body {
        background-color: $grey-6;
        border-bottom-left-radius: $spacer-2;
        border-bottom-right-radius: $spacer-2;
        max-height: calc(100vh - 100px);
        overflow-y: auto;
        padding: $spacer-2;
        position: relative;
    }

    .c-modal-footer {
        border-top: 1px solid $grey-5;
        padding: 15px;
        text-align: right;

        button {
            margin-left: 15px;
        }
    }
}

// Extra legacy

.modal-dialog {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: fixed;
    width: 100%;
    z-index: 10000000000000;

    .modal-content {
        background: none;
        border: none;
        border-radius: 0;
        box-shadow: none;
        min-width: 300px;
        z-index: 10000000;

        .modal-header {
            background: $info-1;
            border: none;
            border-top-left-radius: $spacer-2;
            border-top-right-radius: $spacer-2;
            color: $default-5;
        }

        .modal-body {
            background: $white;
        }

        .modal-footer {
            background: $white;
            border-bottom-left-radius: $spacer-2;
            border-bottom-right-radius: $spacer-2;
        }
    }
}

@keyframes modal-slide-in {

    from {
        opacity: 0;
        transform: translateY(-35%);
    }

    to {
        opacity: 1;
        transform: translateY(0%);
    }
}
