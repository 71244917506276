@use "theme/variables" as *;

.c-item-image-view {

    .photos {
        display: flex;
        flex-wrap: wrap;
        gap: $spacer-1;

        .product-photo {
            cursor: default;
            width: 250px;

            .image {

                img {
                    width: 100%;
                    z-index: 1000;
                }
            }

            .controls {
                align-items: center;
                background: hsl(var(--surface-h) var(--s-2) var(--l-5));
                display: flex;
                gap: $spacer-05;
                justify-content: space-between;
                margin-bottom: $spacer-1;
                padding: $spacer-05;

                .field {
                    margin-bottom: 0;
                }

                .btn {
                    height: $input-height;
                }
            }
        }
    }

    .c-dialog {

        .dialog {
            max-width: 60%;
        }

        img {
            max-width: 100%;
        }
    }
}

.c-stock-image-review {

    table {

        td {

            .item-header {
                align-items: center;
                display: flex;
                gap: $spacer-05;
            }
        }
    }
}
