@use "theme/variables" as *;

.c-rating {
    align-items: center;
    background: $default-5;
    border: 1px solid $default-4;
    border-radius: 0;
    padding: $spacer-1;

    .header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: $spacer-05;

        .title {
            color: $info-1;
            font-size: $font-xl;
        }
    }

    .description {
        color: $grey-1;
    }

    .step-rate {

        textarea {
            min-height: 75px;
        }
    }

    .step-thanks {

        img {
            border: 2px solid $info-1;
            margin-top: $spacer-1;
            max-width: 100%;
        }
    }
}

.c-rating-stars {
    display: flex;
    height: 100%;

    &.readonly {

        &,
        .c-icon,
        .c-icon:hover {
            color: $warning-2;
            cursor: default;
        }
    }

    &:not(.readonly) {

        .c-icon {
            transition: color 0.3s ease;

            &.rated {
                color: $warning-2;
            }
        }
    }
}
