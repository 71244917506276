.c-portal-dashboard.view {
    padding: 0 !important;

    iframe {
        height: 2000px;
        min-width: 100%;
        width: 1px;
    }
}

html.tablet {

    .c-portal-dashboard.view {

        iframe {
            height: 2200px;
        }
    }
}


html.mobile {

    .c-portal-dashboard.view {

        iframe {
            height: 2800px;
        }
    }
}
