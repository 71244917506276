@use "theme/variables" as *;

.c-filters-active {
    background: $default-6;
    width: 100%;
    z-index: 1000;

    .inactive {
        align-items: center;
        animation: show 0.3s ease forwards;
        color: $grey-3;
        display: flex;
        flex-direction: column;
        height: $spacer-8;
        justify-content: center;
        max-height: $spacer-8;
        opacity: 0;
        width: 100%;

        .c-icon {
            color: $grey-3;
        }
    }

    .filters {
        display: flex;
        flex-wrap: wrap;
        height: 100%;

        .term {
            align-items: center;
            animation: fade-in 0.3s ease forwards;
            background: $brand-3;
            box-shadow: inset 1px 1px 0 $default-5;
            color: $white;
            display: flex;
            flex: 0 0 50%;
            font-size: $font-s;
            font-weight: 600;
            height: $spacer-4;
            justify-content: flex-start;
            opacity: 0;
            position: relative;
            width: 50%;

            .marker {
                border-right: 1px solid $brand-5;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: $spacer-05;
                z-index: 1000;
            }

            .icon-close {
                background: hsl(var(--brand-h) var(--s-3) var(--l-2) / 80%);
                color: $brand-6;
                display: none;
                height: 100%;
                left: 0;
                padding: $spacer-1 * 0.75;
                position: absolute;
                top: 0;
                width: 100%;
            }

            span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &:hover {
                background: $brand-3;

                .icon-close {
                    display: block;
                }
            }

            &.match {
                background: $info-3;
                color: $info-5;

                svg {
                    color: $info-5;
                }
            }
        }
    }
}
