@use "theme/variables" as *;

.c-collection-selection {
    background: $default-6;
    border-bottom: 2px solid $default-5;
    display: flex;
    flex-direction: column;

    .selection-wrapper {
        display: flex;
        gap: $spacer-1;
        justify-content: center;

        .c-button {
            flex: 1;
        }
    }
}
