@use "theme/variables" as *;

$gradient-1: hsl(var(--info-h) var(--s-3) var(--l-1));
$gradient-2: hsl(var(--info-h) var(--s-3) calc(var(--l-1) + 2%));

.c-dialog {
    align-items: center;
    backdrop-filter: blur(2px);
    background: rgb(0 0 0 / 50%);
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 10000000;

    &.type-default .dialog .title {
        background:
            linear-gradient(
                to bottom,
                hsl(var(--default-h) var(--s-2) var(--l-4)),
                hsl(var(--default-h) var(--s-2) calc(var(--l-4) + 2%))
            );
        color: $default-1;
    }

    &.type-info .dialog .title {
        background:
            linear-gradient(
                to bottom,
                hsl(var(--info-h) var(--s-3) var(--l-1)),
                hsl(var(--info-h) var(--s-3) calc(var(--l-1) + 2%))
            );
    }


    &.type-warning .dialog .title {
        background:
            linear-gradient(
                to bottom,
                hsl(var(--warning-h) var(--s-3) var(--l-3)),
                hsl(var(--warning-h) var(--s-3) calc(var(--l-3) + 2%))
            );
    }

    &.type-danger .dialog .title {
        background:
            linear-gradient(
                to bottom,
                hsl(var(--danger-h) var(--s-3) var(--l-3)),
                hsl(var(--danger-h) var(--s-3) calc(var(--l-3) + 2%))
            );
    }


    @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance:none) {
            /* stylelint-disable-next-line value-no-vendor-prefix */
            height: -webkit-fill-available;
        }
    }

    .dialog {
        background: $default-5;
        box-shadow: $shadow-down-l;
        max-height: 95vh;
        max-width: $breakpoint-20;
        overflow: auto;

        .title {
            align-items: center;
            border-bottom: 1px $default-1;
            color: $default-4;
            display: flex;
            height: $spacer-6;
            justify-content: space-between;
            padding: $spacer-1;

            .title-text {
                font-size: $font-l;
                text-transform: uppercase;
            }

            .close {
                background: none;
                border: none;
                color: $default-4;
                cursor: pointer;
                margin: 0;
                padding: 0;
                transition: all 0.3s ease-in;

                &:hover {
                    color: $brand-4;
                }
            }

            &::after {
                clear: both;
            }
        }

        .body {
            padding: $spacer-1 * 2;
            position: relative;
        }

        .footer {
            padding: $spacer-1;

            .c-button-group {
                justify-content: flex-end;
            }
        }
    }

    @media (max-width: $breakpoint-20) {
        align-items: center;
        margin: 0;
        width: 100%;

        .dialog {
            width: 100%;
        }
    }
}

