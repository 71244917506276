@use "theme/variables" as *;

.c-progress {

    .label {
        color: $success-1;
        margin-bottom: $spacer-025;
    }

    .bar-container {
        background: $default-4;
        display: flex;
        position: relative;
        width: 100%;

        .bar {
            height: $spacer-3;
            transition: all 0.3s ease;
        }
    }

    .boundaries {
        align-items: center;
        color: $success-1;
        display: flex;
        font-size: $font-xs;
        font-weight: 600;
        justify-content: space-between;
        width: 100%;

        .left {
            color: $success-2;
        }

        .right {
            color: $default-2;
        }
    }

    &.type-danger {

        .bar-container .bar {
            background: $danger-3;
        }
    }

    &.type-info {

        .bar-container .bar {
            background: $info-3;
        }
    }

    &.type-default {

        .bar-container .bar {
            background: $default-3;
        }
    }

    &.type-success {

        .bar-container .bar {
            background: $success-3;
        }
    }


    &.type-warning {

        .bar-container .bar {
            background: $warning-3;
        }
    }
}
