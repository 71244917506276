@use "theme/variables" as *;

.c-crm-relations-edit {

    textarea {
        resize: vertical;
    }

    .promotion-stats {
        background: $default-5;
        border: 1px solid $default-4;
        padding: $spacer-1;

        .stat {
            display: flex;
            gap: $spacer-1;
            padding: $spacer-025 0;

            .key {
                color: $info-1;
                font-weight: 600;
            }
        }
    }
}

img.hello-dialog-logo {
    width: 50px;
}
