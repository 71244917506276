@use "theme/variables" as *;

.c-bar-categories {
    background: $default-4;
    border-bottom: 1px solid $grey-5;
    box-shadow: $shadow-down-d;
    height: $layout-submenu-height;
    margin: 0;
    padding: 0;
    user-select: none;


    .categories-container {
        display: flex;
        gap: $spacer-025;

        .c-button {
            background: $default-5;
            color: $default-1;
            gap: $spacer-1;
            padding: 0 $spacer-1;
            transition: all 0.3s ease;

            &.active {
                background: $info-2;
                color: $info-5;

                svg,
                span {
                    $color: $info-5;
                }
            }

            &.active:hover,
            &:hover {
                background: $info-3;
                color: $info-5;
            }
        }
    }

    .menu {
        align-items: center;
        cursor: pointer;
        display: flex;
        font-size: $font-d;
        height: $layout-submenu-height;
        justify-content: center;
        line-height: $layout-submenu-height;
        text-decoration: none;

        .nav-icon {
            margin-right: $spacer-1;
        }

        &.active {

            .dropdown-body {
                box-shadow: $shadow-down-d;
                display: flex;
                flex-direction: column;
            }
        }

        &.active-child {
            background: $white;
            border-bottom: 2px solid $brand-3;
            color: $brand-3;

            &:not([disabled]):not(.active) .c-icon,
            span {
                color: $brand-3;
            }

            &:hover {
                background: $default-4;
                color: $brand-3;

                &:not([disabled]):not(.active) .c-icon {
                    color: $brand-5;
                }
            }
        }
    }

    .c-dropdown {

        .dropdown-body {
            min-width: 100%;

            .sub-menu {
                align-items: center;
                cursor: pointer;
                display: flex;
                gap: $spacer-1;
                height: $layout-submenu-height;
                justify-content: space-between;
                margin: 0;
                padding: 0 $spacer-1;
                user-select: none;
                white-space: nowrap;

                .count {
                    color: $info-1;
                }
            }
        }
    }

    &:not(.float-panel--active) {

        .menu {
            min-width: 150px;
        }

        .c-dropdown {

            .dropdown-body {
                overflow: auto;

                .sub-menu {
                    background: $grey-6;
                    border-bottom: 2px solid transparent;
                    border-top: 2px solid transparent;
                    color: $grey-1;

                    &:hover,
                    &.active {
                        background: $white;
                        border-bottom-color: $brand-3;
                        color: $default-1;

                        .count {
                            color: $default-1;
                        }
                    }
                }
            }
        }
    }

    &.float-panel--active {
        flex-direction: column;

        .categories-container {
            flex-direction: column;
            justify-content: flex-start;
            max-height: calc(var(--app-height) - #{$spacer-8});
            overflow-y: scroll;

            .c-button {
                justify-content: flex-start;
            }
        }

        &.collapsed {
            max-width: 0;
            opacity: 0;
            overflow: hidden;
        }

        .c-dropdown {
            width: 100%;

            .menu {
                justify-content: flex-start;
                padding: $spacer-1;
                width: 100%;

                &.active-child {
                    background: $info-4;
                    border-bottom: 0;

                    .c-icon,
                    span {
                        color: $info-1;
                    }
                }
            }

            .dropdown-body {
                border: none;
                box-shadow: none;
                display: flex;
                flex-direction: column;
                position: relative;

                .sub-menu {
                    background: $white;
                    padding-left: $spacer-2;
                    padding-right: $spacer-1;

                    &:hover,
                    &.active {
                        border-bottom: 2px solid $brand-3;

                        // background: $brand-3;
                        // color: $brand-5;

                        .count {
                            color: $brand-5;
                        }
                    }
                }
            }
        }
    }
}
