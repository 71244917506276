@use "theme/variables" as *;

.c-purchase-order-costs {
    padding-top: $spacer-3;

    .column {
        max-width: 800px;
    }

    .cost-form {
        display: grid;
        grid-template-columns: auto auto;

        .cost-label {
            grid-column: 1;
        }

        .cost-value {
            grid-column: 2;
        }
    }

    table {

        td {
            padding-right: $spacer-1;
            vertical-align: bottom;
        }

        td.first-row {
            padding-top: $spacer-1;
        }

        td.amount {
            text-align: end;
        }

        td.amount.last-amount {
            border-bottom-style: double;
            padding-bottom: $spacer-05;
        }

        td.cost-label {
            font-weight: bolder;
        }

        td.cost-label.last-amount {
            font-weight: bolder;
            vertical-align: top;
        }

        td.description {
            font-weight: lighter;
        }
    }
}
