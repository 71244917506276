@use "common/lib/mixins" as *;
@use "theme/variables" as *;

.c-collection-view {
    padding-bottom: var(--query-spacer);
    position: relative;

    &.inactive .c-collection-items {

        * {
            cursor: default;
            filter: grayscale(0.3) blur(2px);
            pointer-events: none;
            user-select: none;
        }
    }

    .loading-observable {
        height: $spacer-2;
        margin-top: $spacer-8;
        min-height: $spacer-2;
        width: 100%;
    }

    .loading-indicator {
        color: $brand-3;
        font-size: $font-xl;
        height: 0;
        justify-content: center;
        left: 0;
        position: sticky;
        top: 50%;
        transition: opacity 0.5s ease;
        visibility: hidden;

        .wrapper {
            align-items: center;
            display: flex;
            flex-direction: column;

            span {
                font-size: $font-xl;
            }

            svg {
                @include square($icon-xl);

                color: $brand-3;
                margin-bottom: $spacer-1;
            }
        }
    }

    .c-specs {
        color: $grey-2;
        font-size: $font-s;
        font-weight: 600;
    }

    &.loading {
        min-height: 200px;

        .loading-indicator {
            opacity: 1;
            visibility: visible;
        }

        .product .image,
        .product .container {
            opacity: 0.25;
        }
    }

    &.grid {

        .c-collection-items {
            display: grid;
            grid-gap: var(--query-spacer);
            grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));

            @media (max-width: $breakpoint-10) {
                grid-template-columns: 1fr;
            }
        }
    }

    &.list {

        .c-collection-items {
            align-items: stretch;
            display: grid;
            grid-gap: var(--query-spacer);
            grid-template-columns: 1fr 1fr;
            justify-items: stretch;
        }
    }

    &.table {
        display: flex;
        flex-direction: column;
        width: 100%;

        .row {
            display: flex;
            flex-flow: row wrap;
            margin: 0;
            padding: $spacer-1 0;
            transition: opacity 0.25s ease;
            width: 100%;

            .column {
                flex: 1;
                padding: $spacer-2;

                .no-click {
                    cursor: default;
                }

                &.number {
                    justify-content: flex-end;
                }
            }

            &.row-header {
                // The header of the offer table.
                background: $grey-6;
                box-shadow: $shadow-down-d-l;
                color: $grey-3;
                height: $spacer-8;
                margin-bottom: $spacer-1;
                padding: 0;

                &.sticky {
                    position: sticky;
                    top: 0;
                    z-index: 100;
                }

                .sort-icon {
                    color: $default-3;
                    margin-left: $spacer-05;
                }

                .column {
                    align-content: center;
                    align-items: center;
                    border-bottom: 2px solid transparent;
                    cursor: default;
                    display: flex;
                    height: 100%;

                    &.sortable {
                        cursor: pointer;

                        &:hover,
                        &.sort-active {
                            background: $default-5;
                            border-bottom: 2px solid $info-2;
                            color: $info-2;

                            .sort-icon {
                                color: $info-2;
                            }
                        }
                    }

                    .title {
                        align-items: center;
                        display: flex;
                        font-size: $font-s;
                        height: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        text-transform: uppercase;
                        white-space: nowrap;

                        svg {
                            margin-right: $spacer-05;
                        }
                    }
                }
            }

            &.row-body {

                &:nth-of-type(even) {
                    background: $white;
                    transition: background 0.15s ease;

                    &:hover {
                        background: $brand-5;
                    }
                }

                &:nth-of-type(odd) {
                    background: $grey-6;

                    &:hover {
                        background: $brand-5;
                    }
                }

                &.active {
                    background: $brand-5;
                }

                .column {
                    align-items: center;
                    display: flex;

                    &.lead {
                        margin: 0;
                    }

                    &.ellipsis {
                        min-width: 0;

                        span {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }
            }

            &.row-footer {

                .column {

                    .spinner {
                        color: $brand-3;
                        font-size: $icon-l;
                        line-height: $icon-l;
                    }

                    &.addition-line {
                        justify-content: flex-end;
                        padding-right: 0;
                        text-align: right;
                    }
                }
            }

            .row-group {
                flex: 10;
                flex: var(--grouped-width);
                overflow: hidden;

                @media (min-width: $breakpoint-35) {
                    display: flex;
                    flex: 25;
                    flex: var(--ungrouped-width);
                    justify-content: space-between;
                }
            }

            .row-details {
                flex: 0 1 100%;
            }
        }

        &.loading {

            .row {

                &.row-header {

                    .column {
                        cursor: not-allowed;
                    }
                }

                &:not(.row-header):not(.row-footer) {
                    opacity: 0.3;
                }
            }
        }
    }

    &.size-s {

        .c-collection-items {
            font-size: $font-s;

            .cell {
                min-height: $spacer-4;
                padding: $spacer-1 $spacer-025;
            }
        }
    }

    @media (max-width: $breakpoint-40) {

        &.table {

            .row {

                .column {
                    padding: $spacer-1;
                }
            }
        }
    }

    @media (max-width: $breakpoint-35) {

        &.list {

            .c-collection-items {
                grid-template-columns: 1fr 1fr;
            }
        }

        &.table {

            .row .column {
                padding: $spacer-05;
            }
        }
    }

    @media (max-width: $breakpoint-30) {

        &.list {

            .c-collection-items {
                grid-template-columns: 1fr;
            }
        }
    }

    @media (max-width: $breakpoint-20) {

        &.list {

            .c-collection-items {
                grid-template-columns: 1fr;
            }
        }

        &.table .row {
            gap: $spacer-05;

            .column {
                padding: $spacer-1 * 0.25;
            }
        }
    }

    @media (max-width: $breakpoint-10) {

        &.table {

            .row {

                &.row-header {

                    .column.sortable {

                        .sort-icon {
                            @include square($icon-s);

                            margin-left: 0;
                        }
                    }
                }

                .column {
                    padding: $spacer-1 * 0.125;
                }
            }
        }
    }
}

.no-results {
    align-items: center;
    background: $default-6;
    border: 2px dashed $default-4;
    box-shadow: $shadow-down-d-l;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 400px;
    padding: $spacer-2;
    position: relative;

    .main-text {
        color: $info-2;
        font-size: $font-xl;
        text-align: center;
        text-transform: uppercase;
        z-index: 1000;
    }

    svg {
        @include square(150px);

        animation: bounce 0.3s linear 1;
        color: $default-5;
        position: absolute;
    }

    .search-hint {
        font-size: $font-l;
        font-weight: 600;
        margin-top: $spacer-05;
        white-space: nowrap;
        z-index: 1000;
    }

    .search-terms {
        display: flex;
        flex-direction: column;

        .search-term {
            flex: 1;
            font-size: $font-d;
        }
    }
}
