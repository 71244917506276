@use "theme/variables" as *;

@mixin variant($hue) {
    border: 4px solid hsl(var(--#{$hue}-h) var(--s-2) var(--l-3));
    color: hsl(var(--#{$hue}-h) var(--s-2) var(--l-2));
    filter: drop-shadow(0 6px 3px hsla(var(--#{$hue}-h) var(--s-2) var(--l-1) / 20%));

    &,
    &::before {
        background: hsl(var(--#{$hue}-h) var(--s-2) var(--l-5));
    }

    &:hover {

        &,
        &::before {
            background: hsl(var(--#{$hue}-h) var(--s-3) var(--l-4));
        }
    }

    &:last-child::before {
        background: hsl(var(--#{$hue}-h) var(--s-2) var(--l-3));
    }

    .notification-icon {
        color: hsl(var(--#{$hue}-h) var(--s-3) var(--l-1));
    }
}

.c-notification {
    align-items: center;
    animation: fade-in 0.75s ease;
    border-radius: $spacer-2;
    display: flex;

    // Filter, because of the speech balloon triangle

    min-height: $spacer-8;
    padding: $spacer-1;
    position: relative;
    transition: background 0.3s ease;

    &.type-default { @include variant("default"); }
    &.type-brand { @include variant("brand"); }
    &.type-success { @include variant("success"); }
    &.type-info { @include variant("info"); }
    &.type-warning { @include variant("warning"); }
    &.type-danger { @include variant("danger"); }

    .message {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding-left: $spacer-1;

        .title {
            margin-bottom: $spacer-1;
        }

        .body {
            line-height: 1.5rem;
        }
    }


    &:last-child {

        &::before {
            clip-path: polygon(100% 100%, 100% 0, 0 50%);
            content: "";
            display: block;
            height: 20px;
            left: -20px;
            position: absolute;
            width: 20px;
        }
    }

    &.exit {
        animation: fade-out 0.75s ease;
    }
}
