@use "theme/variables" as *;

.c-pricelist-offeritems {

    .td-price {
        display: flex;
        flex-direction: column;

        .price-base {
            align-items: center;
            display: flex;
            gap: $spacer-05;
            justify-content: flex-end;
        }

        .help-block {
            color: $default-3;
            font-size: $font-xs;
            margin: 0;
            margin-top: -1 * $spacer-05;
        }
    }
}
