@use "common/lib/mixins" as *;
@use "theme/variables" as *;

@keyframes show {

    100% {
        opacity: 1;
        transform: none;
    }
}


.c-collection-items {

    .empty-results {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: $spacer-2;

        .not-found {
            align-items: center;
            display: flex;
            flex-direction: column;
            font-size: $font-xl;

            svg {
                @include square($icon-xxl);

                color: $brand-3;
            }
        }

        .suggest {
            align-items: center;
            display: flex;
            gap: $spacer-1;
            justify-content: center;


            &,
            svg {
                color: $brand-3;
            }
        }


        span {
            color: $default-2;
        }
    }

    .item {
        display: block !important;
        margin: 0;
        margin-bottom: $spacer-05;
        position: relative;

        .wrap {
            overflow: unset;
            white-space: normal;
            white-space: wrap;
        }

        &:nth-child(odd) {
            @include row-style($info-6, $info-4, $grey-1);

            .row-edit {
                background: $default-6;
                border-top: 2px dashed $default-4;
                cursor: default;
            }

            .cell-bulk {
                background: $default-5;
            }

            .row-details {
                border-top: 2px dashed $default-4;
            }
        }

        &:nth-child(even) {
            @include row-style($info-5, $info-4, $grey-1);

            .cell-bulk {
                background: $default-4;
            }

            .row-edit {
                background: $default-5;
                border-top: 2px dashed $default-3;
                cursor: default;
            }

            .row-details {
                border-top: 2px dashed $default-3;
            }
        }

        .cells {
            display: grid;
            position: relative;
            width: 100%;

            .cell {
                align-items: center;
                display: flex;
                min-height: $spacer-6;
                overflow: hidden;
                padding: $spacer-1;
                text-overflow: ellipsis;
                white-space: nowrap;

                .td-group {

                    .header {
                        text-wrap: wrap;
                    }
                }

                &.cell-bulk {
                    padding: 0;
                }

                &.cell-status {
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    gap: $spacer-05;
                    justify-content: center;
                    padding: $spacer-1 $spacer-025;

                    .c-icon {
                        @include square($spacer-1 * 2.5);
                    }
                }

                &.cell-group {
                    gap: $spacer-2;
                }

                &.cell-type-disabled {
                    background: $grey-6;
                    color: $grey-3;
                }

                &.cell-type-hidden {
                    display: none;
                }

                &.cell-type-danger {
                    background: $danger-5;
                }

                &.cell-type-warning {
                    background: $warning-5;
                }
            }

            &:hover {

                .cell-actions {
                    opacity: 1;
                }
            }

            .cell-actions {
                align-items: center;
                background: linear-gradient(90deg, transparent, $default-5); // partially hides content beneath
                bottom: 0;
                display: flex;
                gap: $spacer-1;
                height: 100%;
                justify-content: flex-end;
                max-width: 320px; // Maximum 6 action buttons
                opacity: 0;
                padding-right: $spacer-1;
                position: absolute;
                right: 0;
                width: 100%;
                z-index: 100;
            }

            .cell-status {
                align-items: center;
                display: flex;
                flex-direction: column;
                height: 100%;
                z-index: 100;
            }

            .cell-selection {
                display: flex;
                justify-content: center;
            }
        }

        .row-edit {
            align-items: center;
            display: flex;
            gap: $spacer-1;
            justify-content: flex-end;
            padding: $spacer-1;
            width: 100%;

            .field {
                max-width: 200px;
            }

            input {
                max-width: 200px;
            }
        }

        .row-details {
            display: flex;
            flex-direction: column;
            gap: $spacer-2;
            padding: $spacer-1;
            width: 100%;
        }


        &.edit {
            border: $spacer-025 solid $info-3;
            box-shadow: $shadow-down-d-xl;
            margin-bottom: $spacer-2;
            margin-top: $spacer-2;
            transition: all 0.3s ease;

            .cells {

                .cell-actions {
                    background: linear-gradient(90deg, transparent, $info-4);
                    opacity: 1;
                }
            }
        }

        &.detail:not(.edit) {
            box-shadow: $shadow-down-d-l;
            margin-bottom: $spacer-4;
            transition: all 0.3s ease;

            .cells {

                .cell-actions {
                    opacity: 1;
                }
            }
        }


        &.item-type-surface {

            .td-group .header {
                color: $default-2;
            }

            &:nth-child(odd) {
                @include row-style($default-5, $default-4, $grey-1);
            }

            &:nth-child(even) {
                @include row-style($default-6, $default-4, $grey-1);
            }
        }

        &.item-type-info {

            .td-group .header {
                color: $info-2;
            }

            &:nth-child(odd) {
                @include row-style($info-5, $info-4, $grey-1);
            }

            &:nth-child(even) {
                @include row-style($info-6, $info-4, $grey-1);
            }
        }

        &.item-type-loading {

            .td-group .header {
                color: $grey-2;
            }

            &:nth-child(odd) {
                @include row-style($grey-5, $grey-4, $grey-1);
            }

            &:nth-child(even) {
                @include row-style($grey-6, $grey-4, $grey-1);
            }
        }

        &.item-type-success {

            .td-group .header {
                color: $success-2;
            }


            &:nth-child(odd) {
                @include row-style($success-5, $success-4, $grey-1);
            }

            &:nth-child(even) {
                @include row-style($success-6, $success-4, $grey-1);
            }
        }

        &.item-type-warning {

            .td-group .header {
                color: $warning-2;
            }

            &:nth-child(odd) {
                @include row-style($warning-5, $warning-4, $grey-2);
            }

            &:nth-child(even) {
                @include row-style($warning-6, $warning-4, $grey-1);
            }
        }
    }

    .cells {
        @include td-group;


        .td-wrapper {
            align-items: center;
            display: flex;
        }
    }

    &.has-row-detail {

        .item {
            cursor: row-resize;

            .no-click {
                cursor: default;
            }
        }
    }

    &.has-row-status {
        // Make room for an extra cell with row status icons.

        .cell {

            &.cell-actions {
                margin-right: var(--coll-status-width);
            }
        }
    }

    &.has-row-click {

        .item {
            cursor: pointer;
        }
    }

    &.size-s {
        font-size: $font-s;

        .cells {
            font-size: $font-s;
        }
    }
}
