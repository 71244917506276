@use "theme/variables" as *;
@use "common/lib/mixins" as *;
@use "sass:map";

@mixin variant-action($bg, $fg) {

    &.variant-action {

        &:not(.disabled) {
            background: map.get($bg, "normal");
            border-color: map.get($bg, "active");
            color: map.get($fg, "normal");

            span,
            svg {
                color: map.get($fg, "normal");
            }

            &:active,
            &.active,
            &:hover {
                background: map.get($bg, "active");

                span,
                svg {
                    color: map.get($fg, "active");
                }
            }

            &:focus {
                outline: map.get($fg, "active");
            }
        }

        &.disabled {
            filter: grayscale(0.7);

            &:not(.active) {
                background: map.get($bg, "disabled");
                color: map.get($fg, "disabled");

                span,
                svg {
                    color: map.get($fg, "disabled");
                }
            }

            &.active {
                background: map.get($bg, "disabled");
                border-color: map.get($bg, "active");
                color: map.get($fg, "normal");
            }
        }
    }
}

@mixin variant-context($bg, $fg) {

    &.variant-context {
        background: map.get($bg, "normal");
        border: none;
        transition: 0.3s all ease;

        span,
        .c-icon {
            color: map.get($fg, "normal");
        }


        &::before {
            background: map.get($bg, "active");
        }

        &:not(.disabled) {

            &.active,
            &:hover {
                background: map.get($bg, "active");
                transition: background 0.5s ease-in;

                &::before {
                    transform: scaleX(1);
                }

                span,
                .c-icon {
                    color: map.get($fg, "active");
                }
            }
        }

        &.disabled {
            filter: grayscale(0.4);

            span {
                color: map.get($fg, "disabled");
            }

            &:not(.active) {
                background: $surface-5;

                .c-icon {
                    color: map.get($fg, "disabled");
                }
            }

            &.active {
                background: map.get($bg, "disabled");

                .c-icon {
                    color: map.get($fg, "disabled");
                }
            }
        }
    }
}

@mixin variant-menu($bg, $fg) {

    &.variant-menu {
        background: none;
        font-weight: 500;

        &:not([disabled]):not(.disabled) {
            span { color: map.get($bg, "normal"); }

            &:not(.active) {

                .c-icon { color: $default-1; }

                svg {
                    color: map.get($fg, "normal");
                }

                &:hover {
                    background: $white;

                    .c-icon { color: map.get($fg, "active"); }

                    .amount,
                    span {
                        color: $default-1;
                    }

                    svg {
                        color: map.get($fg, "active");
                    }
                }
            }

            &.active {
                background: $white;
                border-bottom-color: map.get($fg, "active");

                .c-icon { color: map.get($fg, "active"); }

                .amount,
                span,
                svg {
                    color: $default-1;
                }

                &:hover { color: map.get($fg, "active"); }
            }

            .amount,
            span {
                color: $default-1;
            }

            .amount {
                font-size: $font-xxs;
            }
        }


        &[disabled],
        .disabled {
            filter: grayscale(0.4);

            &:not(.active) {
                background: $surface-5;
                .c-icon { color: map.get($fg, "disabled"); }
            }

            &.active {
                background: map.get($bg, "disabled");

                .c-icon { color: map.get($fg, "disabled"); }

                .amount,
                span,
                svg {
                    color: map.get($fg, "disabled");
                }
            }

            span { color: $grey-3; }
        }
    }
}

@mixin variant-toggle($bg, $fg) {

    &.variant-toggle {

        &:not(:disabled) {
            background: map.get($bg, "normal");
            border-color: map.get($bg, "active");
            color: map.get($fg, "normal");

            span {
                color: $grey-2;
            }

            .amount {
                color: $default-1;
                font-weight: 600;
            }

            svg {
                color: map.get($fg, "normal");
            }

            &:active,
            &.active,
            &:hover {
                background: map.get($bg, "active");
                border-color: map.get($fg, "normal");

                svg {
                    color: map.get($fg, "active");
                }
            }

            &:focus {
                outline: map.get($fg, "active");
            }
        }

        &.disabled,
        &[disabled] {
            filter: grayscale(0.4);

            &:not(.active) {
                background: $grey-5;
                border-color: $grey-4;
                color: map.get($fg, "disabled");

                svg {
                    color: map.get($fg, "disabled");
                }
            }

            &.active {
                background: map.get($bg, "disabled");
                border-color: map.get($bg, "active");
                color: map.get($fg, "disabled");
            }
        }
    }
}

@mixin variant-link($bg, $fg) {

    &.variant-link {
        background: none;

        &:not([disabled]) {
            color: map.get($fg, "normal");
            cursor: pointer;

            &:active,
            &.active,
            &:hover {
                color: map.get($fg, "active");
            }
        }

        &[disabled] {
            color: map.get($fg, "disabled");
            cursor: not-allowed;
        }
    }
}

.c-button {
    border: 0;
    cursor: pointer;
    display: flex;
    outline: 0;
    touch-action: manipulation;
    user-select: none;
    white-space: nowrap;

    &.disabled,
    &[disabled] {
        cursor: not-allowed;
    }

    svg {
        // Analytics doesn't use event bubbling; make sure traceable buttons
        // don't have clickable elements in them.
        pointer-events: none;

        &.icon-xl {
            @include square($icon-xl);
        }
    }

    &.type-brand {
        @include variant-action(
            ("normal": $brand-3, "active": $brand-25, "disabled": $brand-3),
            ("normal": $brand-4, "active": $brand-5, "disabled": $brand-2)
        );
        @include variant-context(
            ("normal": $brand-4, "active": $brand-3, "disabled": $brand-4),
            ("normal": $brand-1, "active": $brand-6, "disabled": $brand-3)
        );
        @include variant-link(
            ("normal": $brand-3, "active": $brand-25, "disabled": $brand-4),
            ("normal": $brand-3, "active": $brand-25, "disabled": $brand-4)
        );
        @include variant-menu(
            ("normal": $brand-6, "active": $white, "disabled": $brand-6),
            ("normal": $brand-3, "active": $brand-3, "disabled": $brand-3)
        );
        @include variant-toggle(
            ("normal": $brand-3, "active": $brand-25, "disabled": $brand-4),
            ("normal": $brand-5, "active": $brand-6, "disabled": $brand-25)
        );
    }

    &.type-danger {
        @include variant-action(
            ("normal": $danger-3, "active": $danger-25, "disabled": $danger-4),
            ("normal": $danger-4, "active": $danger-5, "disabled": $danger-3)
        );
        @include variant-context(
            ("normal": $danger-4, "active": $danger-25, "disabled": $danger-4),
            ("normal": $danger-1, "active": $danger-5, "disabled": $danger-3)
        );
        @include variant-link(
            ("normal": $danger-3, "active": $danger-25, "disabled": $danger-4),
            ("normal": $danger-3, "active": $danger-25, "disabled": $danger-4)
        );
        @include variant-menu(
            ("normal": $danger-5, "active": $danger-3, "disabled": $danger-5),
            ("normal": $danger-3, "active": $default-2, "disabled": $danger-3)
        );
        @include variant-toggle(
            ("normal": $danger-5, "active": $danger-3, "disabled": $danger-4),
            ("normal": $danger-3, "active": $danger-6, "disabled": $danger-3)
        );
    }

    &.type-default {
        @include variant-action(
            ("normal": $surface-5, "active": $surface-3, "disabled": $surface-5),
            ("normal": $surface-2, "active": $surface-1, "disabled": $surface-4)
        );
        @include variant-context(
            ("normal": $surface-4, "active": $surface-2, "disabled": $surface-4),
            ("normal": $surface-1, "active": $surface-5, "disabled": $surface-3)
        );
        @include variant-link(
            ("normal": $surface-2, "active": $surface-1, "disabled": $grey-4),
            ("normal": $surface-2, "active": $surface-1, "disabled": $grey-4)
        );
        @include variant-menu(
            ("normal": $surface-5, "active": $white, "disabled": $grey-5),
            ("normal": $surface-3, "active": $default-2, "disabled": $grey-2)
        );
        @include variant-toggle(
            ("normal": $surface-4, "active": $surface-3, "disabled": $grey-4),
            ("normal": $surface-1, "active": $surface-1, "disabled": $grey-3)
        );
    }

    &.type-info {
        @include variant-action(
            ("normal": $info-2, "active": $info-1, "disabled": $info-3),
            ("normal": $info-4, "active": $info-4, "disabled": $info-2)
        );
        @include variant-context(
            ("normal": $info-4, "active": $info-2, "disabled": $info-4),
            ("normal": $info-1, "active": $info-5, "disabled": $info-3)
        );
        @include variant-link(
            ("normal": $info-2, "active": $info-1, "disabled": $info-4),
            ("normal": $info-2, "active": $info-1, "disabled": $info-4)
        );
        @include variant-menu(
            ("normal": $info-5, "active": $white, "disabled": $info-5),
            ("normal": $info-2, "active": $info-2, "disabled": $info-2)
        );
        @include variant-toggle(
            ("normal": $info-5, "active": $info-2, "disabled": $info-4),
            ("normal": $info-2, "active": $info-4, "disabled": $info-3)
        );
    }

    &.type-success {
        @include variant-action(
            ("normal": $success-2,"active": $success-1, "disabled": $success-3),
            ("normal": $success-5, "active": $success-5, "disabled": $success-2)
        );
        @include variant-context(
            ("normal": $success-4,"active": $success-2, "disabled": $success-4),
            ("normal": $success-1, "active": $success-5, "disabled": $success-3)
        );
        @include variant-link(
            ("normal": $success-2,"active": $success-1, "disabled": $success-5),
            ("normal": $success-2, "active": $success-1, "disabled": $success-4)
        );
        @include variant-menu(
            ("normal": $success-5,"active": $white, "disabled": $success-5),
            ("normal": $success-2, "active": $success-2, "disabled": $success-2)
        );
        @include variant-toggle(
            ("normal": $success-4,"active": $success-3, "disabled": $success-4),
            ("normal": $success-2, "active": $success-1, "disabled": $success-3)
        );
    }

    &.type-warning {
        @include variant-action(
            ("normal": $warning-3, "active": $warning-25, "disabled": $warning-3),
            ("normal": $warning-4, "active": $warning-5, "disabled": $warning-2)
        );
        @include variant-context(
            ("normal": $warning-4, "active": $warning-25, "disabled": $warning-5),
            ("normal": $warning-1, "active": $warning-5, "disabled": $warning-3)
        );
        @include variant-link(
            ("normal": $warning-3, "active": $warning-25, "disabled": $warning-5),
            ("normal": $warning-3, "active": $warning-25, "disabled": $warning-4)
        );
        @include variant-menu(
            ("normal": $warning-5, "active": $white, "disabled": $warning-5),
            ("normal": $warning-2, "active": $warning-2, "disabled": $warning-3)
        );
        @include variant-toggle(
            ("normal": $warning-5, "active": $warning-3, "disabled": $warning-4),
            ("normal": $warning-2, "active": $warning-5, "disabled": $warning-3)
        );
    }

    &.variant-action {
        align-items: center;
        border: 1px solid;
        border-radius: $border-radius;
        height: $spacer-5;
        justify-content: center;
        padding: $spacer-1;
        transition: all 0.3s ease;

        span {
            margin-left: $spacer-05;
        }

        &.size-xs {
            border-radius: $border-radius-xs;
            font-size: $font-xs;
            height: $spacer-3;
            svg { @include square($icon-xs); }
        }

        &.size-s {
            border-radius: $border-radius-s;
            font-size: $font-s;
            height: $spacer-4;
            svg { @include square($icon-s); }
        }

        &.size-d {
            border-radius: $border-radius-d;
            font-size: $font-d;
            height: $spacer-5;

            svg { @include square($icon-d); }
        }

        &.size-l {
            border-radius: $border-radius-l;
            font-size: $font-l;
            height: $spacer-6;
            padding: $spacer-1 * 1.5;
            svg { @include square($icon-l); }
        }

        &.size-xl {
            border-radius: $border-radius-xl;
            font-size: $font-xl;
            height: $spacer-7;
            padding: $spacer-1 * 2;
            svg { @include square($icon-xl); }
        }
    }

    &.variant-context {
        align-items: center;
        color: $grey-3;
        flex-direction: column;
        font-weight: 500;
        height: $spacer-8;
        justify-content: center;
        max-height: $spacer-8;
        min-width: $spacer-8;
        padding: $spacer-05 $spacer-2;
        position: relative;

        .c-icon {
            @include square($icon-d);
        }

        &::before {
            bottom: 0;
            content: "";
            height: 2px;
            left: 0;
            position: absolute;
            right: 0;
            transform: scaleX(0);
            transform-origin: 0 0;
            transition: transform 150ms;
            will-change: transform;
        }

        &:not(:last-child) {
            border-right: 1px solid $grey-6;
        }
    }

    &.variant-link {
        background: none;
        cursor: pointer;
        font-weight: 600;
        padding: 0;

        // Extra state to emphasize inactivity.

        &.inactive {
            color: $grey-5;
        }

        svg {
            margin-right: $spacer-05;
        }

        span {
            align-items: center;
            display: flex;
        }

        + .tip {
            cursor: help;
            margin-left: $spacer-05;
        }
    }

    &.variant-menu {
        align-items: center;
        border-bottom: 2px solid transparent;
        border-top: 2px solid transparent;
        display: flex;
        height: $spacer-6;
        justify-content: center;
        padding: 0 $spacer-1;
        position: relative;
        transition: all 0.3s ease;

        &.disabled {
            cursor: not-allowed;
        }

        .amount {
            bottom: 0;
            font-size: $font-xs;
            position: absolute;
            right: $spacer-025;
        }

        span {
            color: $default-3;
            display: flex;
            flex: 1;
            justify-content: flex-start;
            margin-left: $spacer-1;
        }
    }

    &.variant-toggle {
        align-items: center;
        border: 1px solid;
        border-radius: 50%;
        justify-content: center;
        margin: 0;
        padding: 0;
        position: relative;
        transition: all 0.3s ease-out;

        .amount {
            bottom: -1 * $spacer-1;
            color: $white;
            font-size: $font-xs;
            position: absolute;
            right: -1 * $spacer-05;
        }

        &.size-xs {
            @include square($icon-xs * 1.8);
            svg { @include square($icon-xs); }
        }

        &.size-s {
            @include square($icon-s * 1.8);
            svg { @include square($icon-s); }
        }

        &.size-d {
            @include square($icon-d * 1.8);
            svg { @include square($icon-d); }
        }

        &.size-l {
            @include square($icon-l * 1.6);
            svg { @include square($icon-l); }
        }

        &.size-xl {
            @include square($icon-xl * 1.6);
            svg { @include square($icon-xl); }
        }
    }
}

a.c-button {
    text-decoration: none;
}

