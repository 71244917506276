@use "theme/variables" as *;

.c-pager {
    align-items: center;
    display: flex;
    gap: $spacer-1;


    .pagination {
        display: flex;
        list-style: none;

        li {
            cursor: pointer;

            a {
                align-items: center;
                background: $info-5;
                border: none;
                color: $info-1;
                display: flex;
                font-weight: 600;
                height: $spacer-5;
                justify-content: center;
                width: $spacer-5;

                &:hover {
                    background: $info-2;
                    color: $info-5;
                }
            }

            &.active {

                a {
                    background: $info-1;
                    color: $info-4;
                }
            }

            &.hidden {
                display: none;
            }
        }
    }


    nav {

        ul.pagination {
            margin: 0;
        }
    }

    .result-count {
        color: $info-1;
        font-size: $font-d;
        font-style: italic;
        font-weight: 600;
    }

    &::after {
        clear: both;
        content: "";
    }
}
