@use "theme/variables" as *;

.form-text {
    padding-top: $spacer-1;
}

.form-text-address {
    padding-top: 0;
}

.illegal-situation {
    color: $danger-3;
    font-size: larger;
    font-weight: bold;
    padding: 15px;
}
