@use "theme/variables" as *;

.c-ranged-input {
    display: flex;
    flex-direction: column;
    margin-bottom: $input-margin;

    label {
        position: relative;
    }

    .ranges {
        margin-top: $spacer-1;
        position: relative;

        input[type="range"] {
            appearance: none;
            background-color: hsl(var(--info-h) var(--s-2) var(--l-4));
            height: 1px;
            pointer-events: none;
            width: 100%;

            &.first {
                background: none;
                position: absolute;
                z-index: 2;
            }

            &.second {
                left: 0;
                position: absolute;
                top: 0;
                z-index: 1;
            }

            &::-webkit-slider-thumb {
                appearance: none;
                background: hsl(var(--info-h) var(--s-3) var(--l-1));
                border-radius: 50%;
                box-shadow: $shadow-down-d;
                cursor: pointer;
                height: $spacer-2;
                pointer-events: all;
                width: $spacer-2;
            }

            &::-moz-range-track {
                height: $spacer-1 * 0.25;
            }

            &::-moz-range-thumb {
                background: hsl(var(--info-h) var(--s-3) var(--l-1));
                border: none;
                border-radius: 50%;
                box-shadow: $shadow-down-d;
                cursor: pointer;
                height: $spacer-2;
                pointer-events: all;
                width: $spacer-2;
            }
        }
    }
}
