@use "theme/variables" as *;

.c-figures-widget {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: $spacer-1;

    .icon {
        color: $info-2;
        font-size: $icon-l;
    }

    .figure {
        color: $surface-2;
        font-weight: 600;
    }

    .title {
        color: $surface-3;
        font-style: italic;
        margin-bottom: $spacer-1;
        text-align: center;
        white-space: nowrap;
    }

    .sub-title {
        font-size: $font-s;
        font-style: italic;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
