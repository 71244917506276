@use "theme/variables" as *;

.c-filter-text {
    position: relative;

    .title {

        .c-field-text input {
            width: 100%;
        }
    }

    .help {
        color: $default-2;
        font-size: $font-xs;
        font-style: italic;
        font-weight: 300;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }
}
