@use "theme/variables" as *;

.c-field-vat-id {
    align-items: center;
    display: flex;

    &.field-compound {

        button.c-button {
            border-radius: 0;
        }
    }
}
