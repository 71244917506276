@use "theme/variables" as *;

.c-add-to-offer {
    width: 100%;

    dl.spli {
        columns: 2;

        dd {
            break-before: avoid;
        }
    }
}

