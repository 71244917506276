@use "theme/variables" as *;

.c-offer-price {

    .changed-price {

        .list-price {
            column-gap: $spacer-1;
            display: flex;
            justify-content: end;

            .price-up-icon {
                color: $info-2;
            }

            .price-down-icon {
                color: $info-3;
            }
        }

        .original-price {
            color: $grey-3;
            text-decoration-line: line-through;
        }
    }

    .duty {

        .help-block {
            column-gap: $spacer-1;
            font-size: small;
        }
    }
}
