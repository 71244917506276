@use "theme/variables" as *;

.c-panel-left.c-panel-menu {

    &.collapsed {

        .btn-highlight {

            .highlight-tag {
                display: none;
            }
        }
    }

    .c-button.btn-highlight {
        background: $success-4;

        .highlight-tag {
            background: $success-2;
            border-radius: $spacer-2;
            color: $success-5;
            font-weight: 600;
            padding: $spacer-05 $spacer-1;
        }

        &:not([disabled]):not(.disabled) {

            &:hover {
                background: $success-5;

                .highlight-tag {
                    background: $success-5;
                    color: $success-2;
                }
            }

            .c-icon,
            span {
                color: $success-1 !important;
            }

            &.active {
                background: $white;
                border-bottom-color: $success-2;

                .highlight-tag {
                    background: $success-5;
                    color: $success-2;
                }


                .c-icon,
                span {
                    color: $success-5;
                }
            }
        }
    }

    @media (min-width: $breakpoint-35) {

        .logo {
            img.collapsed { display: none; }
            img.collapse { display: block; }
        }

        &.collapsed {

            .logo {
                img.collapsed { display: block; }
                img.collapse { display: none; }
            }
        }
    }

    @media (max-height: $breakpoint-20) {

        .manager {
            display: none;
        }
    }
}
