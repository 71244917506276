@use "theme/variables" as *;

.c-badge {
    align-items: center;
    border-radius: $spacer-4;
    display: inline-flex;
    font-size: $font-s;
    font-weight: 600;
    justify-content: center;
    line-height: 1;
    padding: $spacer-05 $spacer-1;
    white-space: nowrap;

    &.type-default {
        background-color: $default-2;
        color: $default-5;
    }

    &.type-info {
        background-color: $info-2;
        color: $info-5;
    }

    &.type-success {
        background-color: $success-2;
        color: $success-5;
    }

    &.type-danger {
        background-color: $danger-2;
        color: $danger-5;
    }

    &.type-warning {
        background-color: $warning-2;
        color: $warning-5;
    }

    &.type-brand {
        background-color: $brand-2;
        color: $brand-5;
    }
}
