@use "common/lib/mixins" as *;
@use "theme/variables" as *;

.c-version-checker {

    .c-dialog {
        color: $info-1;

        .body {

            .info-box {
                display: flex;
                max-width: 500px;
                padding-bottom: $spacer-2;

                .icon-construction {

                    .c-icon {
                        @include square($icon-xxl);

                        align-items: center;
                        color: $brand-3;
                        display: flex;
                        flex: 1;
                        height: 100%;
                        justify-content: center;
                        margin-right: $spacer-1;
                    }
                }

                .text {

                    p {
                        margin-bottom: $spacer-1;
                    }
                }
            }
        }
    }
}
