@use "theme/variables" as *;

.c-loendersloot-message-details {

    pre.raw-xml {
        height: 300px;
        overflow: auto;
        width: 700px;
    }
}
