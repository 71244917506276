@use "theme/variables" as *;

.c-collection-sorter {
    align-items: center;
    display: flex;
    gap: $spacer-1;
    position: relative;

    .sorter-label {
        color: $grey-3;
    }

    .c-button.sort-placeholder {
        align-items: center;
        background: $default-5;
        border: 1px solid $default-4;
        border-radius: 0;
        color: $info-1;
        display: flex;
        font-weight: 600;
        height: $spacer-5;
        justify-content: flex-start;
        padding: $spacer-05;
        padding-left: $spacer-1;

        span {
            min-width: 100px;
            text-align: left;
        }

        &.active {
            background: $default-4;

            .sort-direction svg,
            .sort-direction {
                color: $info-3;
            }
        }
    }

    .dropdown-body {
        padding: 0;
        top: $spacer-5;
        width: unset;

        .sort-item {
            background: $white;
            border: 0;
            color: $grey-1;
            cursor: pointer;
            font-weight: 400;
            padding: $spacer-1;
            text-align: left;
            user-select: none;
            white-space: nowrap;
            width: 100%;

            &:hover {
                background: $info-2;
                color: $info-6;
            }

            &.active {
                background: $info-1;
                color: $info-5;
            }
        }

        .sort-options {
            border-top: 2px solid $default-5;
            display: flex;

            button {
                background: $white;
                height: $spacer-4;
            }
        }
    }

    .sort-direction {
        align-items: center;
        display: flex;
        height: $spacer-5;
        justify-content: center;
        margin-left: $spacer-05;
        width: $spacer-5;

        &:not(.disabled) {
            background: $default-5;
            border: 1px solid $default-4;
            cursor: ns-resize;
        }

        svg {
            color: $info-1;
        }

        &:hover {

            svg {
                color: $default-2 !important;
            }
        }
    }

    &.disabled {
        cursor: not-allowed;
        pointer-events: none;

        .c-button.sort-placeholder {
            background: $grey-5;
            border-color: $grey-5;
            color: $grey-4;
        }

        .sort-direction {
            background: $grey-5;

            .c-icon {
                color: $grey-4;
            }
        }
    }
}

.c-select {
    align-items: center;
    flex-direction: row;
    gap: $spacer-1;
}
