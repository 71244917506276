@use "theme/variables" as *;

$home: hsl(var(--success-h) var(--s-2) var(--l-2));
$home-i: hsl(var(--success-h) var(--s-2) var(--l-6));
$abroad: hsl(var(--default-h) var(--s-2) var(--l-2));
$abroad-i: hsl(var(--default-h) var(--s-2) var(--l-5));
$office: hsl(var(--info-h) var(--s-3) var(--l-1));
$office-i: hsl(var(--info-h) var(--s-3) var(--l-6));
$sick: hsl(var(--danger-h) var(--s-3) var(--l-1));
$sick-i: hsl(var(--danger-h) var(--s-3) var(--l-6));
$off: hsl(var(--brand-h) var(--s-3) var(--l-3));
$off-i: hsl(var(--brand-h) var(--s-3) var(--l-6));
$vacation: hsl(var(--warning-h) var(--s-3) var(--l-5));
$vacation-i: hsl(var(--warning-h) var(--s-3) var(--l-1));
$holiday: hsl(var(--default-h) var(--s-2) var(--l-4));
$holiday-i: hsl(var(--default-h) var(--s-2) var(--l-1));

.c-user-schedules {

    .vis-item {
        border: 0;
        border-radius: 0;

        &.home {
            background: $home;
            color: $home-i;
        }

        &.abroad {
            background: $abroad;
            color: $abroad-i;
        }

        &.office {
            background: $office;
            color: $office-i;
        }

        &.sick {
            background: $sick;
            color: $sick-i;
        }

        &.off {
            background: $off;
            color: $off-i;
        }

        &.vacation {
            background: $vacation;
            color: $vacation-i;
        }

        &.vis-background.holiday {
            background: $holiday;
            color: $holiday-i;
        }
    }

    .group-highlight {
        background: $default-5;
    }

    .vis-today {
        background: $grey-5;
    }

    .legenda {
        font-style: italic;
    }
}

.badge {

    &.home {
        background: $home;
        color: $home-i;
        width: 80px;
    }

    &.office {
        background: $office;
        color: $office-i;
        width: 80px;
    }

    &.sick {
        background-color: $sick;
        color: $sick-i;
        width: 80px;
    }

    &.vacation {
        background-color: $vacation;
        color: $vacation-i;
        width: 80px;
    }

    &.abroad {
        background-color: $abroad;
        color: $abroad-i;
        width: 80px;
    }
}

