@use "theme/variables" as *;

@keyframes recent {
    0% { color: $surface-3; }
    50% { color: $surface-4; }
    100% { color: $surface-3; }
}

.dashboard-widget {
    flex: 1;
    margin-bottom: $spacer-3;
    max-height: 400px;
    overflow-y: auto;

    .widget-title {
        align-items: center;
        background: $surface-6;
        color: $info-2;
        display: flex;
        height: $spacer-6;
        padding-left: $spacer-1;
        position: sticky;
        text-transform: uppercase;
        top: 0;

        .text {
            flex: 1;
            font-weight: 600;
        }
    }

    .widget-help {
        color: $surface-3;
        font-size: $font-s;
        font-style: italic;
    }

    &.recent {

        .widget-title {
            animation: recent 2s linear infinite;
        }
    }

    .stats {
        // A table-like statistic
        display: flex;
        flex-wrap: wrap;
        gap: $spacer-1;

        .stats-group {
            background: $surface-6;
            display: flex;
            flex: 1;
            flex-direction: column;
            font-size: $font-s;
            justify-content: flex-start;
            padding: $spacer-1;
            width: 50%;

            .stats-group-title {
                align-items: center;
                color: $info-2;
                display: flex;
                height: $spacer-4;
                overflow: hidden;
                text-overflow: ellipsis;
                text-transform: uppercase;
                white-space: nowrap;
            }

            .stat-figure {
                align-items: center;
                background: $surface-5;
                cursor: help;
                display: flex;
                margin-bottom: $spacer-05;
                padding: $spacer-1 $spacer-1;

                &.stat-level-default { color: $default-2; }
                &.stat-level-success { color: $success-2; }
                &.stat-level-info { color: $info-2; }
                &.stat-level-warning { color: $warning-2; }
                &.stat-level-danger { color: $danger-2; }

                i {
                    width: $spacer-2;
                }
            }
        }
    }

    table {
        // A dashboard widget with only a table; e.g. a user status widget.
        position: relative;
        width: 100%;

        th {
            height: $spacer-6;
            position: sticky;
            text-transform: uppercase;
            top: 0;

            &.even,
            &.odd {
                background: $surface-6;

                a {
                    color: $info-2;
                    font-weight: 400;

                    &:hover {
                        color: $info-1;
                    }
                }
            }
        }

        th,
        td {
            padding: $spacer-05;
        }

        tr {

            &.onclick-row:hover {
                background: $surface-5;
                cursor: pointer;
            }

            &.odd {
                background: $surface-6;
            }

            &.even {
                background: $white;
            }
        }
    }
}
