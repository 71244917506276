@use "theme/variables" as *;

.c-purchase-order-item-edit {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    min-width: 1200px;
    row-gap: $spacer-05;

    h3 {
        font-size: 1.4rem;
        margin: $spacer-3;
        min-width: 1200px;
    }

    form {
        flex: 1;
        flex-direction: row;
        flex-wrap: wrap;

        .fieldset-group {
            min-width: 600px;
        }
    }
}
