@use "theme/variables" as *;

.c-purchase-order-edit {

    .help-block {
        display: flex;
    }

    .transport-summary {
        display: flex;
        flex: 1;
        gap: $spacer-1;
        width: 100%;

        .transport {
            align-items: center;
            background: $surface-5;
            border: 1px solid $surface-4;
            color: $info-1;
            display: flex;
            flex: 1;
            flex-direction: column;
            padding: $spacer-1;

            > span {
                color: $info-1;
                font-size: $icon-d;
                padding: $spacer-1;
            }
        }
    }
}

