@use "theme/variables" as *;

.c-panel-filters {
    $disabled-color: $grey-4;
    $disabled-bg: $grey-6;

    display: flex;
    flex-direction: column;
    max-width: $panel-filters-width;
    padding-top: 0;
    position: relative;
    width: 100%;

    &.disabled {

        * {
            pointer-events: none;
        }

        .field {

            button,
            input {
                background: $disabled-bg;
                color: $disabled-color;
            }
        }

        .filter {

            &,
            &.collapsed:not(.active):not(:disabled),
            .title {

                svg,
                .name {
                    background: $disabled-bg;
                    color: $disabled-color;
                }
            }

            .items {

                input[type="radio"]:checked,
                input[type="checkbox"]:checked {
                    background: $disabled-color;
                }

                .item {

                    label {
                        color: $disabled-color;
                    }
                }
            }

            &.c-filter-select-range {

                .items {

                    .noUi-base {

                        .noUi-connects {

                            .noUi-connect {
                                background: $grey-6;
                            }

                            .noUi-tooltip {
                                color: $grey-2;
                            }
                        }

                        .noUi-origin {

                            .noUi-tooltip {
                                color: $grey-3;
                            }
                        }
                    }

                    .noUi-pips {
                        color: $grey-3;
                    }
                }
            }
        }

        .c-filters-active {
            background: $grey-6;
            box-shadow: none !important;

            button {
                background: $grey-5;
                color: $grey-4;
            }
        }
    }

    .scroll-container {
        display: flex;

        // ActiveFilters are kept on the same height as CollectionHeader,
        // until it is being hovered. Decollapse animation requires a separate
        // stacking context from the rest of the filters (hence the large margin-top).
        // margin-top: $spacer-8;
        flex-direction: column;
        gap: $spacer-2;
        max-height: 100%;
        overflow-y: auto;
        padding-top: $spacer-2;

        .filter {

            .field {
                margin: 0;
            }
        }

        .filter-group {
            display: flex;
            flex-direction: column;
        }
    }

    .c-filters-active {
        position: absolute;
        transition: max-height 0.3s ease;
        z-index: 10;
    }

    &:not(.float-panel--active) {

        .c-filters-active {
            box-shadow: $shadow-down-d;
            max-height: $spacer-8;
            min-height: $spacer-8;
            overflow: hidden;
            padding-bottom: $spacer-8;

            &:hover {
                animation: expand 1.5s ease forwards, collapse 0.5s ease backwards;
                max-height: 100%;
            }
        }
    }

    &.float-panel--active {
        background: $grey-6;
        border-right: $grey-5;
        opacity: 1;
        z-index: 10000;// must fall over the menu and the stock table header.

        .c-filters-active {
            position: relative;
        }

        .c-collection-filter-control {
            background: $default-5;
            display: flex;
            position: absolute;
            right: 0;

            // width: 100%;

            .c-button {
                border: 0;
            }
        }

        .scroll-container {
            margin-top: unset;
            padding-bottom: $spacer-2;
        }

        .filter {
            background: $white;
        }

        &.collapsed {
            max-width: 0;
            opacity: 0;
            overflow: hidden;

            .view-actions,
            .filter {
                overflow: visible;
            }
        }

        .toggle {
            display: flex;

            &.collapsed {
                // The toggle can't be properly moved to the nav-sidebar while being collapsed.
                // Within the nav-sidebar, there is a copy of this button that triggers the
                // uncollapse on the panel.
                display: none;
                left: 0;
                top: $spacer-1;
            }

            &.uncollapsed {
                bottom: $spacer-1;
                right: $spacer-1;
            }
        }
    }
}
