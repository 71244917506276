@use "theme/variables" as *;

.c-checkbox {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;

    label {
        flex: 1 0;
    }

    &.select-intend {

        > input {
            background: $brand-4;
        }
    }
}

