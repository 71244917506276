@use "theme/variables" as *;

.c-search-bar {

    input {
        border: 1px solid $input-border-color;

        &:focus {
            outline: 0;
        }
    }
}
