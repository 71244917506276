@use "theme/variables" as *;

.logbook {

    .timeline {
        list-style: none;
        margin: 0;
        max-height: 300px;
        max-width: 300px;
        overflow-y: scroll;
        padding: 0;

        li {
            display: flex;
            padding-bottom: $spacer-1;

            .timeline-icon {
                margin-right: $spacer-1;
            }

            .timeline-header {
                font-size: 14px;
                font-weight: 600;
                margin: 0;
            }
        }
    }

    .timeline-icon {
        margin-left: 5px;
    }
}
