@use "theme/variables" as *;

.c-field-date {

    &.years {
        width: 90px;
    }

    &.months {
        width: 140px;
    }
}

.air-datepicker-cell.-year-.-other-decade-,
.air-datepicker-cell.-day-.-other-month- {
    color: var(--adp-color-other-month);
}

.air-datepicker-cell.-year-.-other-decade-:hover,
.air-datepicker-cell.-day-.-other-month-:hover {
    color: var(--adp-color-other-month-hover);
}

.-disabled-.-focus-.air-datepicker-cell.-year-.-other-decade-,
.-disabled-.-focus-.air-datepicker-cell.-day-.-other-month- {
    color: var(--adp-color-other-month);
}

.-selected-.air-datepicker-cell.-year-.-other-decade-,
.-selected-.air-datepicker-cell.-day-.-other-month- {
    background: var(--adp-background-color-selected-other-month);
    color: #{$white};
}

.-selected-.-focus-.air-datepicker-cell.-year-.-other-decade-,
.-selected-.-focus-.air-datepicker-cell.-day-.-other-month- {
    background: var(--adp-background-color-selected-other-month-focused);
}

.-in-range-.air-datepicker-cell.-year-.-other-decade-,
.-in-range-.air-datepicker-cell.-day-.-other-month- {
    background-color: var(--adp-background-color-in-range);
    color: var(--adp-color);
}

.-in-range-.-focus-.air-datepicker-cell.-year-.-other-decade-,
.-in-range-.-focus-.air-datepicker-cell.-day-.-other-month- {
    background-color: var(--adp-background-color-in-range-focused);
}

.air-datepicker-cell.-year-.-other-decade-:empty,
.air-datepicker-cell.-day-.-other-month-:empty {
    background: none;
    border: none;
}

.air-datepicker-cell {
    align-items: center;
    border-radius: var(--adp-cell-border-radius);
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;
}

.air-datepicker-cell.-focus- {
    background: var(--adp-cell-background-color-hover);
}

.air-datepicker-cell.-current- {
    color: var(--adp-color-current-date);
}

.air-datepicker-cell.-current-.-focus- {
    color: var(--adp-color);
}

.air-datepicker-cell.-current-.-in-range- {
    color: var(--adp-color-current-date);
}

.air-datepicker-cell.-disabled- {
    color: var(--adp-color-disabled);
    cursor: default;
}

.air-datepicker-cell.-disabled-.-focus- {
    color: var(--adp-color-disabled);
}

.air-datepicker-cell.-disabled-.-in-range- {
    color: var(--adp-color-disabled-in-range);
}

.air-datepicker-cell.-disabled-.-current-.-focus- {
    color: var(--adp-color-disabled);
}

.air-datepicker-cell.-in-range- {
    background: var(--adp-cell-background-color-in-range);
    border-radius: 0;
}

.air-datepicker-cell.-in-range-:hover {
    background: var(--adp-cell-background-color-in-range-hover);
}

.air-datepicker-cell.-range-from- {
    background-color: var(--adp-cell-background-color-in-range);
    border: 1px solid var(--adp-cell-border-color-in-range);
    border-radius: var(--adp-cell-border-radius) 0 0 var(--adp-cell-border-radius);
}

.air-datepicker-cell.-range-to- {
    background-color: var(--adp-cell-background-color-in-range);
    border: 1px solid var(--adp-cell-border-color-in-range);
    border-radius: 0 var(--adp-cell-border-radius) var(--adp-cell-border-radius) 0;
}

.air-datepicker-cell.-range-to-.-range-from- {
    border-radius: var(--adp-cell-border-radius);
}

.air-datepicker-cell.-selected- {
    background: var(--adp-cell-background-color-selected);
    border: none;
    color: #{$white};
}

.air-datepicker-cell.-selected-.-current- {
    background: var(--adp-cell-background-color-selected);
    color: #{$white};
}

.air-datepicker-cell.-selected-.-focus- {
    background: var(--adp-cell-background-color-selected-hover);
}


.air-datepicker-body {
    transition: all var(--adp-transition-duration) var(--adp-transition-ease);
}

.air-datepicker-body.-hidden- {
    display: none;
}

.air-datepicker-body--day-names {
    display: grid;
    grid-template-columns: repeat(7, var(--adp-day-cell-width));
    margin: 8px 0 3px;
}

.air-datepicker-body--day-name {
    align-items: center;
    color: var(--adp-day-name-color);
    display: flex;
    flex: 1;
    font-size: 0.8em;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
}

.air-datepicker-body--day-name.-clickable- {
    cursor: pointer;
}

.air-datepicker-body--day-name.-clickable-:hover {
    color: var(--adp-day-name-color-hover);
}

.air-datepicker-body--cells {
    display: grid;
}

.air-datepicker-body--cells.-days- {
    grid-auto-rows: var(--adp-day-cell-height);
    grid-template-columns: repeat(7, var(--adp-day-cell-width));
}

.air-datepicker-body--cells.-months- {
    grid-auto-rows: var(--adp-month-cell-height);
    grid-template-columns: repeat(3, 1fr);
}

.air-datepicker-body--cells.-years- {
    grid-auto-rows: var(--adp-year-cell-height);
    grid-template-columns: repeat(4, 1fr);
}


.air-datepicker-nav {
    border-bottom: 1px solid var(--adp-border-color-inner);
    box-sizing: content-box;
    display: flex;
    justify-content: space-between;
    min-height: var(--adp-nav-height);
    padding: var(--adp-padding);
}

.-only-timepicker- .air-datepicker-nav {
    display: none;
}

.air-datepicker-nav--title,
.air-datepicker-nav--action {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.air-datepicker-nav--action {
    border-radius: var(--adp-border-radius);
    user-select: none;
    width: var(--adp-nav-action-size);
}

.air-datepicker-nav--action:hover {
    background: var(--adp-background-color-hover);
}

.air-datepicker-nav--action:active {
    background: var(--adp-background-color-active);
}

.air-datepicker-nav--action.-disabled- {
    visibility: hidden;
}

.air-datepicker-nav--action svg {
    height: 32px;
    width: 32px;
}

.air-datepicker-nav--action path {
    fill: none;
    stroke: var(--adp-nav-arrow-color);
    stroke-width: 2px;
}

.air-datepicker-nav--title {
    border-radius: var(--adp-border-radius);
    padding: 0 8px;
}

.air-datepicker-nav--title i {
    color: var(--adp-nav-color-secondary);
    font-style: normal;
    margin-left: 0.3em;
}

.air-datepicker-nav--title:hover {
    background: var(--adp-background-color-hover);
}

.air-datepicker-nav--title:active {
    background: var(--adp-background-color-active);
}

.air-datepicker-nav--title.-disabled- {
    background: none;
    cursor: default;
}


.air-datepicker-buttons {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
}

.air-datepicker-button {
    background: rgb(255 255 255 / 0%);
    border: none;
    border-radius: var(--adp-btn-border-radius);
    color: var(--adp-btn-color);
    cursor: pointer;
    display: inline-flex;
    height: var(--adp-btn-height);
}

.air-datepicker-button:hover {
    background: var(--adp-btn-background-color-hover);
    color: var(--adp-btn-color-hover);
}

.air-datepicker-button:focus {
    background: var(--adp-btn-background-color-hover);
    color: var(--adp-btn-color-hover);
    outline: none;
}

.air-datepicker-button:active {
    background: var(--adp-btn-background-color-active);
}

.air-datepicker-button span {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    outline: none;
    width: 100%;
}


.air-datepicker-time {
    align-items: center;
    display: grid;
    grid-column-gap: 12px;
    grid-template-columns: max-content 1fr;
    padding: 0 var(--adp-time-padding-inner);
    position: relative;
}

.-only-timepicker- .air-datepicker-time {
    border-top: none;
}

.air-datepicker-time--current {
    align-items: center;
    display: flex;
    flex: 1;
    font-size: 14px;
    text-align: center;
}

.air-datepicker-time--current-colon {
    line-height: 1;
    margin: 0 2px 3px;
}

.air-datepicker-time--current-hours,
.air-datepicker-time--current-minutes {
    font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
    font-size: 19px;
    line-height: 1;
    position: relative;
    z-index: 1;
}

.air-datepicker-time--current-hours::after,
.air-datepicker-time--current-minutes::after {
    background: var(--adp-background-color-hover);
    border-radius: var(--adp-border-radius);
    bottom: -2px;
    content: "";
    left: -2px;
    opacity: 0;
    position: absolute;
    right: -2px;
    top: -3px;
    z-index: -1;
}

.air-datepicker-time--current-hours.-focus-::after,
.air-datepicker-time--current-minutes.-focus-::after {
    opacity: 1;
}

.air-datepicker-time--current-ampm {
    align-self: flex-end;
    color: var(--adp-time-day-period-color);
    font-size: 11px;
    margin-bottom: 1px;
    margin-left: 6px;
    text-transform: uppercase;
}

.air-datepicker-time--row {
    align-items: center;
    background:
        linear-gradient(
            to right,
            var(--adp-time-track-color),
            var(--adp-time-track-color)
        )
        left 50% / 100% var(--adp-time-track-height) no-repeat;
    display: flex;
    font-size: 11px;
    height: 17px;
}

.air-datepicker-time--row:first-child {
    margin-bottom: 4px;
}

.air-datepicker-time--row input[type="range"] {
    appearance: none;
    background: none;
    cursor: pointer;
    flex: 1;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
}

.air-datepicker-time--row input[type="range"]::-ms-tooltip {
    display: none;
}

.air-datepicker-time--row input[type="range"]:hover::-webkit-slider-thumb {
    border-color: var(--adp-time-track-color-hover);
}

.air-datepicker-time--row input[type="range"]:hover::-moz-range-thumb {
    border-color: var(--adp-time-track-color-hover);
}

.air-datepicker-time--row input[type="range"]:hover::-ms-thumb {
    border-color: var(--adp-time-track-color-hover);
}

.air-datepicker-time--row input[type="range"]:focus {
    outline: none;
}

.air-datepicker-time--row input[type="range"]:focus::-webkit-slider-thumb {
    background: var(--adp-cell-background-color-selected);
    border-color: var(--adp-cell-background-color-selected);
}

.air-datepicker-time--row input[type="range"]:focus::-moz-range-thumb {
    background: var(--adp-cell-background-color-selected);
    border-color: var(--adp-cell-background-color-selected);
}

.air-datepicker-time--row input[type="range"]:focus::-ms-thumb {
    background: var(--adp-cell-background-color-selected);
    border-color: var(--adp-cell-background-color-selected);
}

.air-datepicker-time--row input[type="range"]::-moz-range-thumb {
    background: #{$white};
    border: 1px solid var(--adp-time-track-color);
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
    height: 12px;
    transition: background var(--adp-transition-duration);
    width: 12px;
}

.air-datepicker-time--row input[type="range"]::-ms-thumb {
    background: #{$white};
    border: 1px solid var(--adp-time-track-color);
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
    height: 12px;
    transition: background var(--adp-transition-duration);
    width: 12px;
}

.air-datepicker-time--row input[type="range"]::-webkit-slider-thumb {
    appearance: none;
    background: #{$white};
    border: 1px solid var(--adp-time-track-color);
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
    height: 12px;
    margin-top: calc(var(--adp-time-thumb-size) / 2 * -1);
    transition: background var(--adp-transition-duration);
    width: 12px;
}

.air-datepicker-time--row input[type="range"]::-webkit-slider-runnable-track {
    background: rgb(0 0 0 / 0%);
    border: none;
    color: rgb(0 0 0 / 0%);
    cursor: pointer;
    height: var(--adp-time-track-height);
}

.air-datepicker-time--row input[type="range"]::-moz-range-track {
    background: rgb(0 0 0 / 0%);
    border: none;
    color: rgb(0 0 0 / 0%);
    cursor: pointer;
    height: var(--adp-time-track-height);
}

.air-datepicker-time--row input[type="range"]::-ms-track {
    background: rgb(0 0 0 / 0%);
    border: none;
    color: rgb(0 0 0 / 0%);
    cursor: pointer;
    height: var(--adp-time-track-height);
}

.air-datepicker-time--row input[type="range"]::-ms-fill-lower {
    background: rgb(0 0 0 / 0%);
}

.air-datepicker-time--row input[type="range"]::-ms-fill-upper {
    background: rgb(0 0 0 / 0%);
}


.air-datepicker {
    --adp-font-family:
        -apple-system,
        blinkmacsystemfont,
        "Segoe UI",
        roboto,
        helvetica,
        arial,
        sans-serif,
        "Apple Color Emoji",
        "Segoe UI Emoji",
        "Segoe UI Symbol";
    --adp-font-size: 14px;
    --adp-width: 246px;
    --adp-z-index: 10000;
    --adp-padding: 4px;
    --adp-grid-areas: "nav" "body" "timepicker" "buttons";
    --adp-transition-duration: 0.3s;
    --adp-transition-ease: ease-out;
    --adp-transition-offset: 8px;
    --adp-background-color: #{$white};
    --adp-background-color-hover: #{$grey-5};
    --adp-background-color-active: #{$grey-5};
    --adp-background-color-in-range: #{$grey-5};
    --adp-background-color-in-range-focused: #{$info-4};
    --adp-background-color-selected-other-month-focused: #{$info-1};
    --adp-background-color-selected-other-month: #{$info-2};
    --adp-color: #{$grey-2};
    --adp-color-secondary: #{$grey-3};
    --adp-accent-color: #{$info-3};
    --adp-color-current-date: var(--adp-accent-color);
    --adp-color-other-month: #{$grey-4};
    --adp-color-disabled: #aeaeae;
    --adp-color-disabled-in-range: #939393;
    --adp-color-other-month-hover: #{$grey-3};
    --adp-border-color: #{$surface-4};
    --adp-border-color-inner: #{$surface-5};
    --adp-border-radius: 4px;
    --adp-border-color-inline: #{$surface-4};
    --adp-nav-height: 32px;
    --adp-nav-arrow-color: var(--adp-color-secondary);
    --adp-nav-action-size: 32px;
    --adp-nav-color-secondary: var(--adp-color-secondary);
    --adp-day-name-color: #{$info-2};
    --adp-day-name-color-hover: #{$info-1};
    --adp-day-cell-width: 1fr;
    --adp-day-cell-height: 32px;
    --adp-month-cell-height: 42px;
    --adp-year-cell-height: 56px;
    --adp-pointer-size: 10px;
    --adp-poiner-border-radius: 2px;
    --adp-pointer-offset: 14px;
    --adp-cell-border-radius: 4px;
    --adp-cell-background-color-hover: var(--adp-background-color-hover);
    --adp-cell-background-color-selected: #{$info-2};
    --adp-cell-background-color-selected-hover: #{$info-1};
    --adp-cell-background-color-in-range: #{$info-5};
    --adp-cell-background-color-in-range-hover: #{$info-4};
    --adp-cell-border-color-in-range: var(--adp-cell-background-color-selected);
    --adp-btn-height: 32px;
    --adp-btn-color: var(--adp-accent-color);
    --adp-btn-color-hover: var(--adp-color);
    --adp-btn-border-radius: var(--adp-border-radius);
    --adp-btn-background-color-hover: var(--adp-background-color-hover);
    --adp-btn-background-color-active: var(--adp-background-color-active);
    --adp-time-track-height: 1px;
    --adp-time-track-color: #{$grey-4};
    --adp-time-track-color-hover: #{$info-3};
    --adp-time-thumb-size: 12px;
    --adp-time-padding-inner: 10px;
    --adp-time-day-period-color: var(--adp-color-secondary);
    --adp-mobile-font-size: 16px;
    --adp-mobile-nav-height: 40px;
    --adp-mobile-width: 320px;
    --adp-mobile-day-cell-height: 38px;
    --adp-mobile-month-cell-height: 48px;
    --adp-mobile-year-cell-height: 64px;

    background: var(--adp-background-color);
    border: 1px solid var(--adp-border-color);
    border-radius: var(--adp-border-radius);
    box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
    box-sizing: content-box;
    color: var(--adp-color);
    display: grid;
    font-family: var(--adp-font-family), sans-serif;
    font-size: var(--adp-font-size);
    grid-template-areas: var(--adp-grid-areas);
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, max-content);
    position: absolute;
    transition:
        opacity var(--adp-transition-duration) var(--adp-transition-ease),
        transform var(--adp-transition-duration) var(--adp-transition-ease);
    width: var(--adp-width);
    z-index: var(--adp-z-index);
}

.air-datepicker-overlay {
    --adp-overlay-background-color: rgb(0 0 0 / 30%);
    --adp-overlay-transition-duration: 0.3s;
    --adp-overlay-transition-ease: ease-out;
    --adp-overlay-z-index: 99;

    background: var(--adp-overlay-background-color);
    height: 0;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition:
        opacity var(--adp-overlay-transition-duration)
        var(--adp-overlay-transition-ease),
        left 0s,
        height 0s,
        width 0s;
    transition-delay:
        0s,
        var(--adp-overlay-transition-duration),
        var(--adp-overlay-transition-duration),
        var(--adp-overlay-transition-duration);
    width: 0;
    z-index: var(--adp-overlay-z-index);
}

.air-datepicker:not(.-custom-position-) {
    opacity: 0;
}

.air-datepicker.-from-top- {
    transform: translateY(calc(var(--adp-transition-offset) * -1));
}

.air-datepicker.-from-right- {
    transform: translateX(var(--adp-transition-offset));
}

.air-datepicker.-from-bottom- {
    transform: translateY(var(--adp-transition-offset));
}

.air-datepicker.-from-left- {
    transform: translateX(calc(var(--adp-transition-offset) * -1));
}

.air-datepicker.-active-:not(.-custom-position-) {
    opacity: 1;
    transform: translate(0, 0);
}

.air-datepicker.-active-.-custom-position- {
    transition: none;
}

.air-datepicker.-inline- {
    border-color: var(--adp-border-color-inline);
    box-shadow: none;
    left: auto;
    opacity: 1;
    position: static;
    right: auto;
    transform: none;
}

.air-datepicker.-inline- .air-datepicker--pointer {
    display: none;
}

.air-datepicker.-is-mobile- {
    --adp-font-size: var(--adp-mobile-font-size);
    --adp-day-cell-height: var(--adp-mobile-day-cell-height);
    --adp-month-cell-height: var(--adp-mobile-month-cell-height);
    --adp-year-cell-height: var(--adp-mobile-year-cell-height);
    --adp-nav-height: var(--adp-mobile-nav-height);
    --adp-nav-action-size: var(--adp-mobile-nav-height);

    border: none;
    position: fixed;
    width: var(--adp-mobile-width);
}

.air-datepicker.-is-mobile- * {
    -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
}

.air-datepicker.-is-mobile- .air-datepicker--pointer {
    display: none;
}

.air-datepicker.-is-mobile-:not(.-custom-position-) {
    transform: translate(-50%, calc(-50% + var(--adp-transition-offset)));
}

.air-datepicker.-is-mobile-.-active-:not(.-custom-position-) {
    transform: translate(-50%, -50%);
}

.air-datepicker.-custom-position- {
    transition: none;
}

.air-datepicker-global-container {
    left: 0;
    position: absolute;
    top: 0;
    z-index: 100000000000;
}

.air-datepicker--pointer {
    --pointer-half-size: calc(var(--adp-pointer-size) / 2);

    height: var(--adp-pointer-size);
    position: absolute;
    width: var(--adp-pointer-size);
    z-index: -1;
}

.air-datepicker--pointer::after {
    background: #{$white};
    border-right: 1px solid var(--adp-border-color-inline);
    border-top: 1px solid var(--adp-border-color-inline);
    border-top-right-radius: var(--adp-poiner-border-radius);
    box-sizing: border-box;
    content: "";
    height: var(--adp-pointer-size);
    position: absolute;
    width: var(--adp-pointer-size);
}

.-top-left- .air-datepicker--pointer,
.-top-center- .air-datepicker--pointer,
.-top-right- .air-datepicker--pointer,
[data-popper-placement^="top"] .air-datepicker--pointer {
    top: calc(100% - var(--pointer-half-size) + 1px);
}

.-top-left- .air-datepicker--pointer::after,
.-top-center- .air-datepicker--pointer::after,
.-top-right- .air-datepicker--pointer::after,
[data-popper-placement^="top"] .air-datepicker--pointer::after {
    transform: rotate(135deg);
}

.-right-top- .air-datepicker--pointer,
.-right-center- .air-datepicker--pointer,
.-right-bottom- .air-datepicker--pointer,
[data-popper-placement^="right"] .air-datepicker--pointer {
    right: calc(100% - var(--pointer-half-size) + 1px);
}

.-right-top- .air-datepicker--pointer::after,
.-right-center- .air-datepicker--pointer::after,
.-right-bottom- .air-datepicker--pointer::after,
[data-popper-placement^="right"] .air-datepicker--pointer::after {
    transform: rotate(225deg);
}

.-bottom-left- .air-datepicker--pointer,
.-bottom-center- .air-datepicker--pointer,
.-bottom-right- .air-datepicker--pointer,
[data-popper-placement^="bottom"] .air-datepicker--pointer {
    bottom: calc(100% - var(--pointer-half-size) + 1px);
}

.-bottom-left- .air-datepicker--pointer::after,
.-bottom-center- .air-datepicker--pointer::after,
.-bottom-right- .air-datepicker--pointer::after,
[data-popper-placement^="bottom"] .air-datepicker--pointer::after {
    transform: rotate(315deg);
}

.-left-top- .air-datepicker--pointer,
.-left-center- .air-datepicker--pointer,
.-left-bottom- .air-datepicker--pointer,
[data-popper-placement^="left"] .air-datepicker--pointer {
    left: calc(100% - var(--pointer-half-size) + 1px);
}

.-left-top- .air-datepicker--pointer::after,
.-left-center- .air-datepicker--pointer::after,
.-left-bottom- .air-datepicker--pointer::after,
[data-popper-placement^="left"] .air-datepicker--pointer::after {
    transform: rotate(45deg);
}

.-top-left- .air-datepicker--pointer,
.-bottom-left- .air-datepicker--pointer {
    left: var(--adp-pointer-offset);
}

.-top-right- .air-datepicker--pointer,
.-bottom-right- .air-datepicker--pointer {
    right: var(--adp-pointer-offset);
}

.-top-center- .air-datepicker--pointer,
.-bottom-center- .air-datepicker--pointer {
    left: calc(50% - var(--adp-pointer-size) / 2);
}

.-left-top- .air-datepicker--pointer,
.-right-top- .air-datepicker--pointer {
    top: var(--adp-pointer-offset);
}

.-left-bottom- .air-datepicker--pointer,
.-right-bottom- .air-datepicker--pointer {
    bottom: var(--adp-pointer-offset);
}

.-left-center- .air-datepicker--pointer,
.-right-center- .air-datepicker--pointer {
    top: calc(50% - var(--adp-pointer-size) / 2);
}

.air-datepicker--navigation {
    grid-area: nav;
}

.air-datepicker--content {
    box-sizing: content-box;
    grid-area: body;
    padding: var(--adp-padding);
}

.-only-timepicker- .air-datepicker--content {
    display: none;
}

.air-datepicker--time {
    grid-area: timepicker;
}

.air-datepicker--buttons {
    grid-area: buttons;
}

.air-datepicker--buttons,
.air-datepicker--time {
    border-top: 1px solid var(--adp-border-color-inner);
    padding: var(--adp-padding);
}

.air-datepicker-overlay.-active- {
    height: 100%;
    opacity: 1;
    transition:
        opacity var(--adp-overlay-transition-duration)
        var(--adp-overlay-transition-ease),
        height 0s,
        width 0s;
    width: 100%;
}

