@use "theme/variables" as *;

.c-collection-status {
    align-items: center;
    color: $grey-2;
    display: flex;
    font-weight: 600;
    padding-right: $spacer-05;

    svg {
        color: $info-1;
        margin-right: $spacer-05;
    }
}
