@use "common/lib/mixins" as *;
@use "theme/variables" as *;

.carousel,
.c-carousel-slides,
.c-carousel-slides .item {
    height: inherit;
}

.c-carousel {
    height: 100%;
    position: relative;

    .inline {
        height: 100%;
    }

    :not(.inline) {

        .detail-view {
            width: 75%;
        }
    }

    .detail-view {
        display: flex;
        height: 100%;
        margin: 0 auto;
        position: relative;


        .btn-close {
            position: absolute;
            right: $spacer-1;
            top: $spacer-1;
            z-index: 1000;
        }


        @media (max-width: $breakpoint-20) {
            height: 100vh;
            width: 100%;
        }
    }

    .c-carousel-slides {
        height: 100%;
        width: 100%;
    }

    .c-carousel-controls {

        &.left { left: $spacer-1; }
        &.right { right: $spacer-1; }
    }
}

.c-carousel-controls {
    bottom: calc(50% - 20px);
    position: absolute;
    z-index: 1;
}

.c-carousel-indicators {
    bottom: 0;
    left: 50%;
    list-style: none;
    margin-left: -30%;
    padding-left: 0;
    position: absolute;
    text-align: center;
    width: 60%;
    z-index: 15;

    li {
        @include square($icon-d);

        border: 2px solid $default-4;
        border-radius: $spacer-2;
        cursor: pointer;
        display: inline-block;
        margin: $spacer-05;
        transition: all 0.3s ease;

        &.active {
            background: $info-2;
        }
    }
}

.c-carousel-slides {
    display: flex;
    height: auto;
    position: relative;
    width: 100%;

    @media (max-width: $breakpoint-10) {
        height: 300px;
    }

    &.has-detail {

        .item.active {
            cursor: zoom-in;
        }
    }

    .item {
        display: flex;
        justify-content: center;
        opacity: 0;
        position: absolute;
        transition: all 0.5s ease-in-out;
        width: 100%;

        a {
            color: $grey-5;

            &:hover {
                color: $brand-3;
            }
        }

        img {
            background: $grey-5;
            object-fit: cover;
            width: 100%;
        }

        &.active {
            opacity: 1;
            z-index: 1;
        }

        > img,
        > a > img {
            @include img-responsive;

            line-height: 1;
        }
    }

    .rows {
        // Multiple items carousel
        position: relative;
        width: 100%;

        .row {
            display: flex;
            flex: 1;
            opacity: 0;
            pointer-events: none;
            position: absolute;
            transition: all 1s ease-in-out;
            user-select: none;
            width: 100%;

            &.active {
                opacity: 1;
                pointer-events: auto;
                position: relative;
            }

            .row-item {
                position: relative;
                width: calc(100% / 4);

                img {
                    max-height: 100%;
                    max-width: 100%;
                }
            }
        }
    }
}

