@use "theme/variables" as *;

.sales-manager {

    .c-checkbox-group {
        max-height: 200px;
        overflow: auto;
    }

    .cb-wrapper-1 {
        padding: $spacer-05;
    }

    .item {
        display: flex;
        gap: $spacer-05;
    }
}
