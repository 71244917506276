@use "theme/variables" as *;

.c-toggle-label {
    border: none;

    label {
        border: none;
        font-size: $font-s;
        font-weight: 600;
        padding-left: 0;

        .btn {
            height: $input-height;
            padding: $spacer-05;
        }

        .toggle {
            border: none;
        }
    }

    .toggle-handle {
        background: $surface-4;
        border: none;
        width: $spacer-1;
    }
}
