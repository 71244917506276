@use "theme/variables" as *;

.offer {

    &.offer-items {

        table > tbody.table-row.is-hidden {
            background-color: $warning-5;

            &:hover {
                background-color: $warning-4;
            }
        }
    }
}

.relation-details {

    h3 {

        a {
            margin-left: 10px;
        }
    }
}

.disabled-table-column {
    color: $grey-3;
}

/* Indicate changed inputs in bulk update mode. */

[type="checkbox"].changed + label::before {
    background-color: $warning-3;
    border-color: $warning-2;
}

[type="text"].changed {
    background-color: $warning-3;
    border-color: $warning-2;
    color: $grey-6;

    &:hover,
    &:focus,
    &:active {
        background-color: $warning-3;
        border-color: $warning-2;
    }
}

[type="number"].changed {
    background-color: $warning-3;
    border-color: $warning-2;
    color: $grey-6;

    &:hover,
    &:focus,
    &:active {
        background-color: $warning-3;
        border-color: $warning-2;
    }
}
