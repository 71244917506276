@use "theme/variables" as *;

.c-widget-stock-value {

    .widget-body {
        display: flex;

        .item {
            align-items: center;
            color: $surface-2;
            display: flex;
            flex: 1;
            flex-direction: column;
            font-size: $font-l;
            font-weight: 600;
            justify-content: center;

            i {
                color: $info-2;
                font-size: $icon-l;
                padding: $spacer-05;
                text-align: center;
                width: $spacer-6;
            }

            span {
                font-size: $font-s;
            }
        }
    }
}
