@use "theme/variables" as *;

.c-cell-availability {

    &.td-group {
        display: flex;
        justify-content: center;

        .stock-info {
            align-items: flex-start;
            color: $info-1;
            display: flex;
            font-size: $font-s;
            gap: $spacer-05;

            .stat {
                display: flex;
                gap: $spacer-010;
            }
        }

        &.stock-age-group {
            align-items: flex-end;

            .stock-age {
                align-items: center;
                display: flex;
                gap: $spacer-05;
            }
        }
    }
}
