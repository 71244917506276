@use "theme/variables" as *;

.filter-sidebar-wrapper {
    display: flex;

    h2 {
        color: $info-2;
        margin-bottom: $spacer-1;
        margin-top: 0;
    }

    .c-filter-sidebar {
        min-width: 350px;

        .control-label {
            font-weight: 600;
        }
    }

    .filter-result {
        width: 100%;
    }

    .noUi-horizontal {
        // Legacy filter styling
        margin-top: $spacer-5;
        padding: 0 $spacer-2;
    }
}
