@use "theme/variables" as *;

.c-field-select-many {
    max-width: 200px;

    select {
        max-width: 200px;

        option {
            max-width: 200px;
        }
    }

    &.variant-default {
        gap: $spacer-05;
        margin-bottom: $spacer-2;

        select {
            max-width: 200px;
            width: 100%;
        }

        .control {
            align-items: end;
            display: flex;

            .c-button {
                border-radius: 0;
            }
        }

        .selection-list {
            background: $default-5;
            border: 1px solid $default-4;
            display: flex;
            flex-wrap: wrap;
            gap: $spacer-1;
            padding: $spacer-1;

            .selection-item {
                align-items: center;
                background: $default-4;
                border: 1px solid $info-3;
                border-radius: $spacer-05;
                color: $info-2;
                display: flex;
                padding: $spacer-1 $spacer-025;
            }
        }
    }

    &.variant-inline {
        height: 100%;
        position: relative;

        .c-dropdown {

            .btn-dropdown {
                height: $input-height;
                width: 100%;
            }

            &.active {

                .btn-dropdown {
                    border: 1px solid $input-active-border;


                    .c-icon,
                    span {
                        color: $info-2;
                    }
                }

                .dropdown-body {
                    display: flex;
                    flex-direction: column;
                    max-height: $input-height * 6;

                    .scroll-container {
                        max-height: $input-height * 6;
                        overflow-y: scroll;
                        user-select: none;

                        label {
                            cursor: pointer;
                            font-size: $font-d;
                            font-weight: 400;
                            height: $input-height;
                            padding: 0 $spacer-1;


                            &:hover {
                                background: $default-5;
                                color: $default-2;
                            }
                        }
                    }
                }
            }

            &.disabled {
                border: 1px solid $grey-4;
            }

            &:not(.disabled) {

                .btn-dropdown {
                    border: 1px solid $input-border-color;

                    &.disabled {
                        background: $default-5;

                        .c-icon,
                        span {
                            color: $default-3;
                        }
                    }

                    &:not(.disabled) {
                        background: $input-bg;

                        .c-icon,
                        span {
                            color: $default-1;
                        }
                    }
                }
            }
        }
    }
}
