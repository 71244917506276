@use "theme/variables" as *;

.rates {
    display: flex;
    justify-content: space-evenly;

    small {
        color: #969696;
        font-size: 80%;
    }
}
