@use "theme/variables" as *;

.collection-counter {
    color: hsl(var(--info-h) var(--s-3) var(--l-1));
    display: inline-flex;
    flex-direction: column;
    font-weight: 600;

    .btn {
        margin-bottom: $spacer-1;
    }
}
