@use "theme/variables" as *;

.c-field-composed-money {
    max-width: 275px;
    min-width: 125px;

    .control {
        align-items: center;

        .c-field-composed-currency {


            select,
            select option {
                background: $info-2;
                color: $info-6;
            }
        }
    }

    &:not(.currency) {

        .control {

            input {
                border-right: 1px solid $default-4;
            }
        }
    }
}
